import { 
  IonPage,
  IonContent,
} from '@ionic/react'
import { selectAll, select } from 'd3-selection'
import React, { useState, useEffect, useContext } from 'react'
import ReactMarkdown from 'react-markdown/with-html'
import { RouteComponentProps } from 'react-router-dom'
import './WebPage.css'
import Track from '../components/Track'
import PageTabs from '../components/PageTabs'
import SpinnerCentre from '../components/SpinnerCentre'
import AppContext from '../context/AppContext'

interface PageProps extends RouteComponentProps<{
  page: string;
}> {}

const WebPage: React.FC<PageProps> = ({match}) => {

  const [markdown, setMarkdown] = useState('')
  const { setBusy } = useContext(AppContext)

  let md:string
  if (window.location.hostname.indexOf('staging') > -1 || window.location.hostname === 'localhost') {
    // On staging cluster and localhost, info pages come from infopages_edit.
    md = `${process.env.REACT_APP_DBAPI_URL}infopages_edit/md/${match.params.page}.md`
  } else {
    md = `${process.env.REACT_APP_DBAPI_URL}infopages/md/${match.params.page}.md`
  }

  useEffect(() => {
    async function readMarkdown() {
      setBusy(true)
      let found = false
      await fetch(md, { method: 'HEAD' }).then(res => {found = res.ok})
      if (found){
        await fetch(md).then(res => res.text()).then (text => {
          if (window.location.hostname === 'localhost') {
            // Resources in text that contains relative pathnames to image files will not
            // work on localhost because served from a different port. Also replace
            // infopages with infopages_edit on localhost.
            text = text.replace(/\/infopages\//g, `${process.env.REACT_APP_DBAPI_URL}infopages_edit/`)
          } else if (window.location.hostname.indexOf('staging') > -1) {
            // On staging cluster, reset infopages relative paths paths to infolive.
            text = text.replace(/\/infopages\//g, `/infopages_edit/`)
          }
          setMarkdown(text)
        })
      } else {
        setMarkdown('not found')
      }
      setBusy(false)
    }
    readMarkdown()
  }, [md, setBusy])

  useEffect(() => {
    // For faq page, hide info and show only if user clicks on question.
    // If multiple paras after a question, they must be enclosed in a div.
    if (match.params.page.startsWith('faq')) {
      selectAll('.mdPage h2+div, .mdPage h3+div, .mdPage h4+div').style('display', 'none')
      selectAll('.mdPage h2+p, .mdPage h3+p, .mdPage h4+p').style('display', 'none')
      selectAll('.mdPage h2, .mdPage h3, .mdPage h4').style('cursor', 'pointer')
      selectAll('.mdPage h2, .mdPage h3, .mdPage h4').on('click', (e) => {
        const d3para = select(e.target.nextSibling)
        const shown = d3para.style('display') !== 'none'
        select(e.target.nextSibling).style('display', shown ? 'none' : '')
      })
    }
  })

  let content
  //let title=''
  if (markdown && markdown !== 'not found') {
    // Extract comments from markdown. We are using this sytax for a comment:
    // [//]: # (Comment in here)
    // See https://www.jamestharpe.com/markdown-comments/
    // const comments = markdown
    //   .split(/\r?\n/)
    //   .filter(md => md.startsWith('[//]: # ('))
    //   .map(c => c.substring(9, c.length-1))

    // // Look for page title in comments
    // comments.forEach(c => {
    //   if (c.startsWith('title: ')) {
    //     title = c.substring(7)
    //   }
    // })

    content = <div className='mdPage'><ReactMarkdown escapeHtml={false} source={markdown} /></div>
  } else if (markdown === 'not found') {
    //title = 'Content not found'
    content = (
      <>
        <p>
          The markdown page referenced cannot be found: <b>{md}</b>
        </p>
      </>
    )
  }

  return (<>
    <Track/>
    <IonPage>
    <PageTabs/>
      <IonContent className='infoContent'>
        <SpinnerCentre/>
        {content}
      </IonContent>
    </IonPage>
  </>)
}

export default WebPage