import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA from './components/ReactGAProxy.js';
import * as serviceWorker from './serviceWorker';


// Init Google Analytics
// UA - deprecated
// ReactGA.initialize('UA-19063127-31', 
//   { 
//     debug: process.env.REACT_APP_GA_DEBUG === 'true'
//   }
// )
ReactGA.initialize('G-JDP3REMBXY'); // GA4
//ReactGA.set({ anonymizeIp: true }); IP anonymization is on by default in GA4

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register()

// Workaround for ionic bug which means that when month radio button
// selection presented, it does not automatically scroll to the selected
// month if not in view.
// https://www.androidbugfix.com/2022/01/ionic-4-ion-select-does-not-scroll-to.html
window.addEventListener('ionAlertDidPresent', (e:any) => {
  const selected = (e.target as HTMLElement).querySelector('[aria-checked="true"]')
  selected && selected.scrollIntoView()
})