import React, { useState } from 'react'
import { IonInput, IonButton, IonToolbar, IonList, IonItem, IonModal, IonContent, IonHeader, IonFooter } from '@ionic/react'
import { checkGridRef, getGrCentroid } from './bigr'
import { logActivity } from '../js/logapi'
import './FindPlacePopover.css'

const FindPlacePopover = ({showFindPlace, setShowFindPlace, map}) => {

  const [searchStr, setSearchStr] = useState('')
  const [matches, setMatches] = useState([])

  const dismiss = function() {
    setSearchStr('')
    setShowFindPlace(false)
  }

  const search = (str) => {
    let validGR = true
    let gr
    try {
      gr = checkGridRef(str)
    } catch {
      validGR = false
    }
    if (validGR) {
      searchGr(str, gr.precision)
    } else {
      searchNominatim(str)
    }
  }

  const searchGr = (gr, precision) => {
    const centroid = getGrCentroid(gr).centroid
    let zoom
    if (precision === 100000) {
      zoom = 8
    } else if (precision === 10000) {
      zoom = 11
    } else if (precision === 5000) {
      zoom = 13
    } else if (precision === 2000) {
      zoom = 14
    } else if (precision === 1000) {
      zoom = 15
    } else {
      zoom = 16
    }
    logActivity({id: 'locate_gr', gr: gr, lat: centroid[1], lon: centroid[0]})

    map.setView([centroid[1], centroid[0]], zoom);
    dismiss()
  }

  const searchNominatim = (str) => {

    logActivity({id: 'nominatim', interaction: 'search', value: str})

    const url=`https://nominatim.openstreetmap.org/search?q=${str}&format=json`
    //console.log(url)
    fetch(url, {method: 'GET'})
    .then(response => {
      if (response.status !== 200) {
        throw new Error(String(response.status))
      } else {
        return response.json()
      }
    })
    .then(json => {
      setMatches(json.filter((m) => {
        const lat = Number(m.lat)
        const lon = Number(m.lon)
        return (lat > 48 && lat < 63 && lon > -11 && lon < 4)
      }))
      //console.log(json)
    })
    .catch((error) => {
      console.log('Nominatim error', error)
    })
  }

  const placeSelected = (m) => {
    console.log('clicked', m)

    logActivity({id: 'nominatim', interaction: 'locate', value: m.display_name, lat: Number(m.lat), lon: Number(m.lon)})

    map.setView([Number(m.lat), Number(m.lon)], 13);
    dismiss()
  }

  let matchList = matches.map((m) => {
    return <IonItem key={m.place_id} button onClick={(e) => {placeSelected(m)}}>
      <p style={{fontSize: 'small'}}>{m.display_name}</p>
    </IonItem>
  })
  let cssClass = 'findPlacePopoverPopulated'
  if (!matchList.length) {
    matchList = null
    cssClass = 'findPlacePopoverNoResults'
  }

  return (
    <IonModal cssClass={cssClass} isOpen={showFindPlace}>
      <IonHeader>
        <IonToolbar style={{padding: '0 0.5em'}}>
          <IonInput
              placeholder='Grid ref or place name'
              value={searchStr}
              onIonChange={e => setSearchStr(e.detail.value)}
          ></IonInput> 
          <IonButton slot="end" size='small' onClick={() => search(searchStr)}>Search</IonButton>
          <IonButton slot="end" size='small' onClick={() => dismiss()} fill='clear' color='dark'>x</IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {matchList}
        </IonList>
      </IonContent>
      <IonFooter>
       
      </IonFooter>
    </IonModal>
  )
}
export default FindPlacePopover
