import React, { FunctionComponent, useContext, useRef } from 'react'
import { GeoJSON } from 'react-leaflet'
import ScoreContext from '../context/ScoreContext'
import AppContext from '../context/AppContext'
//import ReactGA from './ReactGAProxy.js'
import { logActivity } from '../js/logapi'

interface AccessGeojsonProps {
  type: string,
  geojson: string,
  zIndex?: number,
  lkey?: number,
  setGeojson: any,
  pathOpts: any,
  pointToLayer: any,
  onEachFeature: any,
  display: boolean
}

const AccessGeojsonLayer: FunctionComponent<AccessGeojsonProps> = ({type, geojson, zIndex, lkey, setGeojson, pathOpts, pointToLayer, onEachFeature, display}) => {
  const { lat, lon } = useContext(ScoreContext)
  const { radiusDecideScore} = useContext(AppContext)

  const fetching: any = useRef(false)
  const fetchno: any = useRef(0)
  fetchno.current++

  if (geojson==='fetch' && !fetching.current){

    //console.log('fetching', type)
    fetching.current = true

    // ReactGA.event({
    //   category: 'Decide score map',
    //   action: `Fetch Geojson map - ${type}`
    // })
    logActivity({id: 'score_gjson', type: type, lat: lat, lon: lon, rad: radiusDecideScore}) 

    fetch(`${process.env.REACT_APP_SCORE_SERVER_URL}score/gjson?lat=${lat}&lon=${lon}&rad=${radiusDecideScore}&type=${type}`, {method: 'GET'})
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(String(response.status))
        } else {
          return response.json()
        }
      })
      .then((json) => {
        if (json.length === 1 && !json[0]) {
          setGeojson('')
        } else {
          setGeojson(json)
        }
      }).catch((error) => {
        setGeojson('')
      }).finally(() => {
        fetching.current=false
      })
    return null
  } else if (geojson && geojson!=='fetch') {

    return display ? (
      <>
        <GeoJSON 
          data={JSON.parse(geojson)} 
          key={lkey ? lkey : ''} // Key is always changed (random number) to ensure correct ordering of geojson layers
          pathOptions={pathOpts} 
          pointToLayer={pointToLayer} 
          onEachFeature={onEachFeature} 
          eventHandlers={{
            click: () => {
              //console.log('marker clicked')
            },
          }}
        /></>) : null
  } else {
    return null
  }
}
export default AccessGeojsonLayer
