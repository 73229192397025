import MenuTabs from './components/MenuTabs'
import Page from './pages/Page'
import Blogs from './pages/Blogs'
import Newsletter from './pages/Newsletter'
import WebPage from './pages/WebPage'
import ScoreMap from './pages/ScoreMap'
import Walkthrough from './components/Walkthrough'
import React, { useState, useEffect } from 'react'
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import UserContext from './context/UserContext'
import AppContext from './context/AppContext'
import ScoreContext from './context/ScoreContext'
import socialMediaFeeds from './resources/socialMediaFeeds.json'
import ls from 'local-storage'
import { logActivity} from './js/logapi'
import './App.css'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
// import { setTimeout } from 'timers'

const App: React.FC = () => {

  // Parse URL params
  const params = new URLSearchParams(window.location.search)
  let paramLat:any = params.get('lat')
  let paramLon:any = params.get('lon')
  let paramDlat:any = params.get('dlat')
  let paramDlon:any = params.get('dlon')
  let paramZoom:any = params.get('zoom')
  let paramScore:any = params.get('score') 
  let paramSuggestions:any = params.get('suggestions')
  let paramSugmax:any = params.get('sugmax')
  let paramPopup:any = params.get('popup')
  let paramName:any = params.get('group')
  let paramMonth:any = params.get('month')


  useEffect(() => {
    if (window.location.search) {
      // If app started with params, log this.
      // Need to do it with a Timeout otherwise App won't be properly mounted at this point
      // and logActivity will faile (because ReactGA.clientId() will fail).

      const activity:any = {id: 'app_init'}
      params.forEach((v,p) => {
        activity[p] = v
      })
      console.log('params', params)
      setTimeout(() => {
        logActivity(activity)
      }, 1000)
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Convert paramZoom to number
  if (paramZoom !== null) paramZoom = Number(paramZoom)

  // Both paramDlat and paramDlon must be present, or neither
  if (paramDlat === null) paramDlon = null
  if (paramDlon === null) paramDlat = null
  // Convert paramDlat and paramDlon to number
  if (paramDlat !== null) paramDlat = Number(paramDlat)
  if (paramDlon !== null) paramDlon = Number(paramDlon)

  // Both paramLat and paramLon must be present, or neither
  if (paramLat === null) paramLon = null
  if (paramLon === null) paramLat = null
  // Convert paramLat and paramLon to number
  if (paramLat !== null) paramLat = Number(paramLat)
  if (paramLon !== null) paramLon = Number(paramLon)

  // If paramLat and paramLon are not present, but paramDlat and paramDlon are, then equalise them
  if (paramLat === null) paramLat = paramDlat
  if (paramLon === null) paramLon = paramDlon

  // If paramDlat and paramDlon are not present, but paramLat and paramLon are, then equalise them
  if (paramDlat === null) paramDlat = paramLat
  if (paramDlon === null) paramDlon = paramLon

  // paramScore can only be set if paramDlat/paramDlon are set
  paramScore = paramScore === 'true' && paramDlat !== null

  // paramSuggestions can only be set if paramScore is set
  paramSuggestions = paramSuggestions === 'true' && paramScore

  // Convert sugmax to a number
  if (paramSugmax !== null) paramSugmax = Number(paramSugmax)

  // paramPopup can only be set if paramLat/paramLon set
  paramPopup = paramPopup === 'true' && paramLat !== null

  // if paramName not set, set to default of butterfly
  paramName = paramName ? paramName : 'butterfly'

  // User state (stored in context)
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [firstname, setFirstname] = useState("")
  const [fullname, setFullname] = useState("")
  const [useData, setUseData] = useState(false)
  const [interestMoths, setInterestMoths] = useState(false)
  const [interestButterflies, setInterestButterflies] = useState(false)
  const [interestGrasshoppers, setInterestGrasshoppers] = useState(false)

  // App state
  const [accessLevel, setAccessLevel] = useState('')
  const [tool, setTool] = useState("")
  const [path, setPath] = useState("")
  const [showOpts, setShowOpts] = useState(false)
  const [generalConsent, setGeneralConsent] = useState(false)
  const [commsConsent, setCommsConsent] = useState('')
  const [otherPlatformConsent, setOtherPlatformConsent] = useState(false)
  const [ispotUsername, setIspotUsername] = useState("")
  const [irecordUsername, setIrecordUsername] = useState("")
  const [busy, setBusy] = useState(false)
  const [mapLat, setMapLat] = useState(paramLat !== null ? paramLat : 52.5)
  const [mapLon, setMapLon] = useState(paramLon !== null ? paramLon : -1.5)
  const [mouseLat, setMouseLat] = useState(0)
  const [mouseLon, setMouseLon] = useState(0)
  const [mapZoom, setMapZoom] = useState(paramZoom  !== null ? paramZoom : 8)
  const [blogs, setBlogs] = useState([])
  const [showHelp, setShowHelp] = useState(false)
  const [helpIdentifier, setHelpIdentifier] = useState('')
  const [configTaxa, setConfigTaxa] = useState([])
  const [showWalkthrough, setShowWalkthrough] = useState(true)

  // Score map options
  const [showNudges, setShowNudges] = useState(true)
  const [maxNudges, setMaxNudges] = useState(paramSugmax !== null ? paramSugmax : 20)
  const [showRowAccess, setShowRowAccess] = useState(false)
  const [showPointAccess, setShowPointAccess] = useState(false)
  const [showGreenspaceAccess, setShowGreenspaceAccess] = useState(false)
  const [showOpenAccess, setShowOpenAccess] = useState(false)
  const [showNtAccess, setShowNtAccess] = useState(false)
  const [showLondonAccess, setShowLondonAccess] = useState(false)
  const [showDecideScoreLayer, setShowDecideScoreLayer] = useState(true)
  const [opacityDecideScoreLayer, setOpacityDecideScoreLayer] = useState(0.6)
  const [scaleDecideScoreLayer, setScaleDecideScoreLayer] = useState(true)
  const [thresholdDecideScoreLayer, setThresholdDecideScoreLayer] = useState(0)
  const [showDecideScoreTarget, setShowDecideScoreTarget] = useState(true)
  const [radiusDecideScore, setRadiusDecideScore] = useState(5000)
  const [scoreTaxa, setScoreTaxa] = useState([paramName])
  const [scoreColour, setScoreColour] = useState("viridis_truncated")
  const [clickLat, setClickLat] = useState(paramLat !== null ? paramLat : 0)
  const [clickLon, setClickLon] = useState(paramLon !== null ? paramLon : 0)
  const [selectedHtml, setSelectedHtml] = useState("")
  const [selectedFtr, setSelectedFtr] = useState({})
  const [selectedFtrMetadata, setSelectedFtrMetadata] = useState({})
  const [useFogmap, setUseFogmap] = useState(false)
  const months = ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec']
  const d = new Date()
  const [month, setMonth] = useState(paramMonth !== null ? paramMonth : months[d.getMonth()])
  
  let lsSmfeed: string = String(ls('smfeed')) 
  lsSmfeed = lsSmfeed !== 'null' ? lsSmfeed : socialMediaFeeds[0].id
  const [mediaFeed, setMediaFeed] = useState(lsSmfeed)

  const user = {
    username: username,
    setUsername: setUsername,
    email: email,
    setEmail: setEmail,
    firstname: firstname,
    setFirstname: setFirstname,
    fullname: fullname,
    setFullname: setFullname,
    mediaFeed: mediaFeed,
    setMediaFeed: setMediaFeed,
    useData: useData,
    setUseData: setUseData,
    interestMoths: interestMoths,
    setInterestMoths: setInterestMoths,
    interestButterflies: interestButterflies,
    setInterestButterflies: setInterestButterflies,
    interestGrasshoppers:  interestGrasshoppers,
    setInterestGrasshoppers: setInterestGrasshoppers,
    generalConsent: generalConsent,
    setGeneralConsent: setGeneralConsent,
    commsConsent: commsConsent,
    setCommsConsent: setCommsConsent,
    otherPlatformConsent: otherPlatformConsent,
    setOtherPlatformConsent: setOtherPlatformConsent,
    ispotUsername: ispotUsername,
    setIspotUsername: setIspotUsername,
    irecordUsername: irecordUsername,
    setIrecordUsername: setIrecordUsername,
  }

  const app = {
    accessLevel: accessLevel,
    setAccessLevel: setAccessLevel,
    tool: tool,
    setTool: setTool,
    path: path,
    setPath: setPath,
    showOpts: showOpts,
    setShowOpts: setShowOpts,
    showNudges: showNudges,
    setShowNudges: setShowNudges,
    maxNudges: maxNudges,
    setMaxNudges: setMaxNudges,
    showRowAccess: showRowAccess,
    setShowRowAccess: setShowRowAccess,
    showPointAccess: showPointAccess,
    setShowPointAccess: setShowPointAccess,
    showGreenspaceAccess: showGreenspaceAccess,
    setShowGreenspaceAccess: setShowGreenspaceAccess,
    showOpenAccess: showOpenAccess,
    setShowOpenAccess: setShowOpenAccess,
    showNtAccess: showNtAccess,
    setShowNtAccess: setShowNtAccess,
    showLondonAccess: showLondonAccess,
    setShowLondonAccess: setShowLondonAccess,
    showDecideScoreLayer: showDecideScoreLayer,
    setShowDecideScoreLayer: setShowDecideScoreLayer,
    opacityDecideScoreLayer: opacityDecideScoreLayer,
    setOpacityDecideScoreLayer: setOpacityDecideScoreLayer,
    scaleDecideScoreLayer: scaleDecideScoreLayer,
    setScaleDecideScoreLayer: setScaleDecideScoreLayer,
    thresholdDecideScoreLayer: thresholdDecideScoreLayer,
    setThresholdDecideScoreLayer: setThresholdDecideScoreLayer,
    showDecideScoreTarget: showDecideScoreTarget,
    setShowDecideScoreTarget: setShowDecideScoreTarget,
    radiusDecideScore: radiusDecideScore,
    setRadiusDecideScore: setRadiusDecideScore,
    scoreTaxa: scoreTaxa,
    setScoreTaxa: setScoreTaxa,
    scoreColour: scoreColour,
    setScoreColour: setScoreColour,
    busy: busy,
    setBusy: setBusy,
    radiusMin: 1000,
    radiusMax: 30000,
    //surveyLink: 'https://ceh-online-surveys.onlinesurveys.ac.uk/feedback-about-the-decide-tool-updated-version',
    //surveyLink: 'mailto:decide@ceh.ac.uk?subject=DECIDE tool feedback',
    surveyLink: '/info/feedback',
    mapLat: mapLat,
    setMapLat: setMapLat,
    mapLon: mapLon,
    setMapLon: setMapLon,
    mapZoom: mapZoom,
    setMapZoom: setMapZoom,
    blogs: blogs,
    showHelp: showHelp,
    setShowHelp: setShowHelp,
    helpIdentifier: helpIdentifier,
    setHelpIdentifier: setHelpIdentifier,
    mouseLat: mouseLat,
    setMouseLat: setMouseLat,
    mouseLon: mouseLon,
    setMouseLon: setMouseLon,
    configTaxa: configTaxa,
    setConfigTaxa: setConfigTaxa,
    showWalkthrough: showWalkthrough,
    setShowWalkthrough: setShowWalkthrough
  }

  const blogApiUrl = 'https://public-api.wordpress.com/wp/v2/sites/decidenature.wordpress.com/posts'
  if (blogs.length === 0) {
    fetch(blogApiUrl)
      .then(response => response.json())
      .then(data => {
        const dataBlogs = data.map((db: any) => {
          return {
            url: db.guid.rendered,
            title: db.title.rendered
          }
        })
        setBlogs(dataBlogs)
      }).catch((error) => {
        console.log('Error fetching blogs', error)
      })
  }

  const [scoreImageUrl, setScoreImageUrl] = useState(paramScore ? 'fetch' : '')
  const [nudgesJson, setNudgesJson] = useState(paramSuggestions ? 'fetch' : '')
  const [nudgeJson, setNudgeJson] = useState(paramPopup ? 'fetch' : '')
  const [singleNudgesJson, setSingleNudgesJson] = useState('')
  const [prowGeojson, setProwGeojson] = useState('')
  const [greenspaceGeojson, setGreenspaceGeojson] = useState('')
  const [accesspointGeojson, setAccesspointGeojson] = useState('')
  const [accesslandGeojson, setAccesslandGeojson] = useState('')
  const [ntGeojson, setNtGeojson] = useState('')
  const [londonGeojson, setLondonGeojson] = useState('')
  const [radius, setRadius] = useState(5000)
  //const [lat, setLat] = useState(0)
  //const [lon, setLon] = useState(0)
  const [lat, setLat] = useState(paramDlat !== null ? paramDlat : 0)
  const [lon, setLon] = useState(paramDlon !== null ? paramDlon : 0)
  const [scoreEnabled, setScoreEnabled] = useState(true)

  let initDevOpts= {
    show: window.location.search === '?devel' ?  true : false, 
    maskOpacity: 0.5,
    minOpacity: 0,
    maxOpacity: 1,
    scoreThreshold: 0,
    flatten: '3sd',
    nudgesymbol: 'ring'
  }
 
  const [devOpts, setDevOpts] = useState(initDevOpts)

  const score = {
    scoreImageUrl: scoreImageUrl,
    setScoreImageUrl: setScoreImageUrl,
    nudgesJson: nudgesJson,
    setNudgesJson: setNudgesJson,
    nudgeJson: nudgeJson,
    setNudgeJson: setNudgeJson,
    singleNudgesJson: singleNudgesJson,
    setSingleNudgesJson: setSingleNudgesJson,
    prowGeojson: prowGeojson,
    setProwGeojson: setProwGeojson,
    greenspaceGeojson: greenspaceGeojson,
    setGreenspaceGeojson: setGreenspaceGeojson,
    accesspointGeojson: accesspointGeojson,
    setAccesspointGeojson: setAccesspointGeojson,
    accesslandGeojson: accesslandGeojson,
    setAccesslandGeojson: setAccesslandGeojson,
    ntGeojson: ntGeojson,
    setNtGeojson: setNtGeojson,
    londonGeojson: londonGeojson,
    setLondonGeojson: setLondonGeojson,
    radius: radius,
    setRadius: setRadius,
    lat: lat,
    setLat: setLat,
    lon: lon,
    setLon: setLon,
    scoreEnabled: scoreEnabled,
    setScoreEnabled: setScoreEnabled,
    devOpts: devOpts,
    setDevOpts: setDevOpts,
    clickLat: clickLat,
    setClickLat: setClickLat,
    clickLon: clickLon,
    setClickLon: setClickLon,
    selectedHtml: selectedHtml,
    setSelectedHtml: setSelectedHtml,
    selectedFtr: selectedFtr,
    setSelectedFtr: setSelectedFtr,
    selectedFtrMetadata: selectedFtrMetadata,
    setSelectedFtrMetadata: setSelectedFtrMetadata,
    useFogmap: useFogmap,
    setUseFogmap: setUseFogmap,
    month: month,
    setMonth: setMonth
  }
  
  return (
    <AppContext.Provider value={app}>
      <UserContext.Provider value={user}>
        <ScoreContext.Provider value={score}>
          <IonApp>
            {/* <img id='early-access' src={earlyAccess} alt='Early access logo'></img> */}
            <Walkthrough />
            <IonReactRouter>
              <IonSplitPane contentId="main">
                <MenuTabs />
                <IonRouterOutlet id="main">
                  <Switch>
                    {/* <Route path="/user" component={User} /> */}
                    {/* <Route path="/(app|opts)/scoremap/:page" component={ScoreMapPage} /> */}
                    {/* <Route path="/(app|opts)/scoremap" component={ScoreMapPage} /> */}
                    <Route path="/(app|opts)/scoremap" component={ScoreMap} />
                    <Route path="/app/page/:name" component={Page} />
                    <Route path="/info/:page" component={WebPage} />
                    <Route path="/app/blogs" component={Blogs} />
                    <Route path="/app/newsletter" component={Newsletter} />
                    <Redirect from="/" to="/opts/scoremap" exact />
                  </Switch>
                </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>
          </IonApp>
        </ScoreContext.Provider>
      </UserContext.Provider>
    </AppContext.Provider>
  );
};

export default App;
