import ReactGA from '../components/ReactGAProxy'

const logActivity = async (action) => {

  // Log data will be written to a different file depending on app source
  let app
  if (window.location.hostname === 'localhost') {
    app = 'localhost'
  } else if (window.location.hostname.indexOf('staging') > -1) {
    app = 'staging'
  } else {
    app = 'production'
  }

  const logdata = {'app': app, 'clientId': ReactGA.clientId(), action: action}

  //Rename id property to aaid
  action.aaid = action.id
  delete(action.id)

  //console.log('clientId', ReactGA.clientId())
  //console.log('logdata', logdata)

  return fetch(`${process.env.REACT_APP_DBAPI_URL}applog`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(logdata)
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error(String(response.status))
    } else {
      return response.json()
    }
  }).then((json) => {
    //console.log('Request successful', json)
    return json
  }).catch((error) => {
    console.log('error', error)
    return []
  }).finally(() => {
    // Cleanup
  })
}

export { logActivity }