import React, {useContext, useState} from 'react'
import { IonModal, IonFooter, IonToolbar, IonIcon, IonButton, IonButtons, IonContent} from '@ionic/react'
import { 
  closeCircleSharp,
  closeCircleOutline,
  arrowForwardCircleSharp,
  arrowForwardCircleOutline, 
  arrowBackCircleSharp,
  arrowBackCircleOutline, 
} from 'ionicons/icons';
import AppContext from '../context/AppContext'
import './Walkthrough.css'
import logo from '../images/Decide_artwork_RGB.png'
import marker from '../images/walkthrough-marker.png'
import walkCreateMap from '../images/walk-create-map.png'
import walkPriorityMap from '../images/walk-priority-areas-map.png'
import walkGetSuggestions from '../images/walk-get-suggestions.png'
import walkSuggestionsMap from '../images/walk-suggestions.png'
import walkSuggestionsMapSelect from '../images/walk-suggestions-select-2.png'
import walkSuggestionDetail from '../images/walk-suggestions-detail.png'
import walkChangeSpOpts from '../images/walk-change-sp-opts.png'
import walkChangeMapOpts from '../images/walk-change-map-opts.png'
import walkHelp1 from '../images/walk-help1.png'
import walkHelp2 from '../images/walk-help2.png'
import walkRecord from '../images/walk-irecord.jpg'

const Walkthrough: React.FC = (props) => {
  const { showWalkthrough, setShowWalkthrough} = useContext(AppContext)
  const [pageN, setPageN] = useState(1)
  const className = pageN !== 1 ? 'imageBackground' : ''
  return <>import './SpinnerCentre.css'
      <IonModal id='nudgePopup' cssClass='nudgePopover' isOpen={showWalkthrough} backdropDismiss={false}>
        <IonContent scrollY={false} className={className}>
          <WalkPage pageN={pageN}/>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons  slot="start">
              <IonButton className="walkthrough-button" onClick={e => setShowWalkthrough(false)}>
                <IonIcon slot="start" ios={closeCircleSharp} md={closeCircleOutline}></IonIcon>
                End walkthrough
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton className="walkthrough-button" disabled={pageN===1} onClick={e => setPageN(pageN-1)}>
                <IonIcon slot="start" ios={arrowBackCircleSharp} md={arrowBackCircleOutline}></IonIcon>
                Previous
              </IonButton>
              <IonButton className="walkthrough-button" disabled={pageN===12} onClick={e => setPageN(pageN+1)}>
                <IonIcon slot="end" ios={arrowForwardCircleSharp} md={arrowForwardCircleOutline}></IonIcon>
                Next
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal>
  </>

}
export default Walkthrough

interface helpTextProps {
  text: string,
  width: number,
  topOffset: number,
  leftOffset: number,
  type?: string
}
const HelpText: React.FC<helpTextProps> = (props) => {

  const img = document.getElementById('walkthrough-image')
  let correction = 1
  if (img) {
    correction = img.offsetWidth/440
  }

  let borderStyle = `${10*correction}px solid #ec1c24`
  let fontSize = `${correction}em`
  if (props.type) {
    if (props.type === 'info') {
      borderStyle = `${5*correction}px solid orange`
      fontSize = `${correction*0.8}em`
    }
  }

  return <>
    <div style={{
      position: 'absolute', 
      top: props.topOffset * correction, 
      left: props.leftOffset * correction, 
      width: props.width * correction,
      backgroundColor: 'white',
      opacity: 0.9,
      border: borderStyle, 
      borderRadius: `${10*correction}px`,
      padding: '0.5em',
      fontSize: fontSize,
    }}>
      {props.text}
    </div>
  </>
}

interface WalkPageProps {
  pageN: number
}
const WalkPage: React.FC<WalkPageProps> = (props) => {

  let wPage = <></>
  switch (props.pageN) {
    case 1:
      wPage = <WalkPage1/>
      break
    case 2:
      wPage = <WalkPage2/>
      break
    case 3:
      wPage = <WalkPage3/>
      break
    case 4:
      wPage = <WalkPage4/>
      break
    case 5:
      wPage = <WalkPage5/>
      break
    case 6:
      wPage = <WalkPage6/>
      break
    case 7:
      wPage = <WalkPage7/>
      break
    case 8:
      wPage = <WalkPage8/>
      break
    case 9:
      wPage = <WalkPage9/>
      break
    case 10:
      wPage = <WalkPage10/>
      break
    case 11:
      wPage = <WalkPage11/>
      break
    case 12:
      wPage = <WalkPage12/>
      break
    default:
      wPage = <p>Help page: {props.pageN}</p>
  }
  return wPage
}

const WalkPage1: React.FC = () => {
  return <>
    <img src={logo} alt="Decide logo" style={{width: '100%'}}/>
    <div style={{margin: '1em'}}>
        <img src={marker} alt="Marker pin" style={{float:'left', width: '70px', marginRight: '1em'}}/>
        <div style={{}}>
          <p>Do you want to discover new places to record wildlife?</p>
          <p>Do you want to record nature in the places where it matters most?</p>
        </div>
        <div style={{border: '3px solid #50c46a', borderRadius: '10px', padding: '1em'}}>
          If yes, this DECIDE Tool shows you where records of butterflies and moths are most needed. Let's start with a quick walkthrough to get you up and running in no time!
        </div>
    </div>
  </>
}

const WalkPage2: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkCreateMap} alt="Walkthrough - create map button" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text='Click the button to show recording priorities at your chosen location.' width={200} leftOffset={200} topOffset={35}/>
    </div>
  </>
}

const WalkPage3: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkPriorityMap} alt="Walkthrough - prioritiy area map" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text='Brighter (more yellow) colours show higher priority for recording.' width={200} leftOffset={190} topOffset={95}/>
    </div>
  </>
}

const WalkPage4: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkGetSuggestions} alt="Walkthrough - get suggestions" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text='Click button to automatically generate suggested locations for recording.' width={200} leftOffset={200} topOffset={65}/>
    </div>
  </>
}

const WalkPage5: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkSuggestionsMap} alt="Walkthrough - suggestion pins" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text='Suggested locations for recording are shown with pins over the priority map.' width={200} leftOffset={35} topOffset={300}/>
      <HelpText text="Remember it is your responsibility to stay safe and legal when recording." type="info" width={130} leftOffset={300} topOffset={335}/>    
    </div>
  </>
}

const WalkPage6: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkSuggestionsMapSelect} alt="Walkthrough - select location" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text='You can select a pin or click anywhere on the map to explore a place of your choosing.' width={245} leftOffset={5} topOffset={330}/>
      <HelpText text="Remember it is your responsibility to stay safe and legal when recording." type="info" width={130} leftOffset={300} topOffset={335}/>    
    </div>
  </>
}

const WalkPage7: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkSuggestionDetail} alt="Walkthrough - location information" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text="You'll discover why the selected location is important..." width={200} leftOffset={220} topOffset={10}/>
      <HelpText text="...and what to expect (based on currently-available information)." width={200} leftOffset={15} topOffset={350}/>
      <HelpText text="Remember it is your responsibility to stay safe and legal when recording." type="info" width={130} leftOffset={300} topOffset={335}/>  
    </div>
  </>
}

const WalkPage8: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkChangeSpOpts} alt="Walkthrough - species and season selection" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text="You can change the species group and season." width={200} leftOffset={40} topOffset={90}/>
    </div>
  </>
}

const WalkPage9: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkChangeMapOpts} alt="Walkthrough - map view" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text="You can change the map view." width={200} leftOffset={160} topOffset={120}/>
    </div>
  </>
}

const WalkPage10: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkHelp1} alt="Walkthrough - help toggle" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text="Select the help toggle to get tips for using the Tool." width={200} leftOffset={220} topOffset={120}/>
    </div>
  </>
}

const WalkPage11: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkHelp2} alt="Walkthrough - more help" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text="Click on Help to find out lots more about the tool, including 'more about the maps and models'." width={200} leftOffset={220} topOffset={120}/>
    </div>
  </>
}

const WalkPage12: React.FC = () => {
  return <>
    <div style={{position: 'relative'}}>
      <img id='walkthrough-image' src={walkRecord} alt="Walkthrough - iRecord" style={{position: 'absolute', width: '100%'}}/>
      <HelpText text="Use the Tool, plan where to go and then record using existing tools, such as iRecord." width={350} leftOffset={10} topOffset={145}/>
    </div>
  </>
}