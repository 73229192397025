import { logActivity } from './logapi.js' 

const logLocation = () => {
  const ret = {
    lat: null,
    lon: null,
    message: ''
  }
  const logResult = ret => {
    logActivity({id: 'current_location', lat: ret.lat, lon: ret.lon, message: ret.message}) 
  }

  if (navigator.geolocation) {  
    navigator.geolocation.getCurrentPosition(position => {
      ret.lat = position.coords.latitude
      ret.lon = position.coords.longitude
      ret.message = "geolocation successful"
      logResult(ret)
    }, error => {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          ret.message = "user denied the request for geolocation"
          break
        case error.POSITION_UNAVAILABLE:
          ret.message = "location information is unavailable"
          break
        case error.TIMEOUT:
          ret.message = "the request to get user location timed out"
          break
        default: //error.UNKNOWN_ERROR:
          ret.message = "an unknown error occurred"
          break
      }
      logResult(ret)
    })
  } else {
    ret.message = "geolocation is not supported by the browser"
    logResult(ret)
  }
}
export { logLocation }