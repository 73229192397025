import { IonSelectOption, IonSelect, IonInput, IonToggle, IonList, IonItem, IonLabel, IonItemDivider, IonRange, IonRadioGroup, IonRadio, IonCard, IonCardContent } from '@ionic/react'
//import { IonSelect, IonSelectOption } from '@ionic/react'
import React, { useContext, useEffect } from 'react'
//import ReactGA from './ReactGAProxy.js'
import { Link } from 'react-router-dom'
import './ScoreMapOpts.css'
import AppContext from '../context/AppContext'
import ScoreContext from '../context/ScoreContext'
import { logActivity } from '../js/logapi.js' 
//import { selectAll } from 'd3-selection'

const ScoreMapOpts: React.FC = () => {

  const { setScoreImageUrl } = useContext(ScoreContext)
  const { useFogmap, setUseFogmap} = useContext(ScoreContext)
  const { opacityDecideScoreLayer,  setOpacityDecideScoreLayer} = useContext(AppContext)
  const { thresholdDecideScoreLayer,  setThresholdDecideScoreLayer} = useContext(AppContext)
  const { maxNudges, setMaxNudges } = useContext(AppContext)
  const { showRowAccess,  setShowRowAccess} = useContext(AppContext)
  const { showPointAccess,  setShowPointAccess} = useContext(AppContext)
  const { showGreenspaceAccess,  setShowGreenspaceAccess} = useContext(AppContext)
  const { showOpenAccess,  setShowOpenAccess} = useContext(AppContext)
  const { showNtAccess,  setShowNtAccess} = useContext(AppContext)
  const { showLondonAccess,  setShowLondonAccess} = useContext(AppContext)
  const { scoreTaxa, setScoreTaxa} = useContext(AppContext)
  const { setScoreColour} = useContext(AppContext)
  const { setNudgesJson, setSingleNudgesJson} = useContext(ScoreContext)
  const { prowGeojson, setProwGeojson} = useContext(ScoreContext)
  const { accesspointGeojson, setAccesspointGeojson} = useContext(ScoreContext)
  const { greenspaceGeojson, setGreenspaceGeojson} = useContext(ScoreContext)
  const { accesslandGeojson, setAccesslandGeojson} = useContext(ScoreContext)
  const { ntGeojson, setNtGeojson} = useContext(ScoreContext)
  const { londonGeojson, setLondonGeojson} = useContext(ScoreContext)
  const { showHelp,  setShowHelp} = useContext(AppContext)
  const { setHelpIdentifier} = useContext(AppContext)
  const { month, setMonth} = useContext(ScoreContext)
  const { configTaxa } = useContext(AppContext)
  
  const maximumNudges=50
  const months = getMonths(scoreTaxa[0])

  function getMonths(taxon:string) {
    // Get the months that taxa is configured to work with
    const config = configTaxa.find(t => t.machine === taxon)
    let months:Array<string> = []
    if (config) {
      months = config.months.split('-')
    }
    return months
  }
  
  function taxaChanged(taxon:string) {
    setScoreTaxa([taxon])
    if (getMonths(taxon).indexOf(month) === -1) {
      // Reset month if invalid for taxon
      setMonth('all_year')
    }
    setScoreImageUrl('fetch')
    setNudgesJson('')
    setSingleNudgesJson('')
  }

  function thresholdChange(threshold: any) {
    setThresholdDecideScoreLayer(1-threshold)
    setScoreImageUrl('fetch')
  }

  function monthChange(month: string) {
    setMonth(month)
    setScoreImageUrl('fetch')
    setNudgesJson('')
    setSingleNudgesJson('')
  }

  useEffect(() => {
    // Attach onclick and onmouseover events to items in the
    // menu list which 
    const items = document.getElementsByTagName(`ion-item`)
    for(let i = 0;i < items.length; i++){
      const item = items[i]
      item.onclick = e => {
        setHelpIdentifier(`main-menu#${item.id}`)
      }
      item.onmouseover = e => {
        setHelpIdentifier(`main-menu#${item.id}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configTaxa])

  return (<>
    <IonCard color='light'>
      <IonCardContent>
        Remember - keeping safe and legal when recording is <Link to="/info/user_agreement">your responsibility</Link>.
        For more information on the DECIDE score and the map tool: <Link to="/info/decide_more_info">further info</Link>
      </IonCardContent>
    </IonCard>

    {/* We use the id property of IonItem lists here to identify the item
    for use with the HelpPanel component. So give all items a unique ID which
    must correspond with the key used for help text in HelpPanel*/}
    <IonList>
      <IonItem lines='none' id='show-help'>
        <IonLabel>Help: {showHelp ? 'shown' : 'hidden'}</IonLabel>
        <IonToggle color="primary" checked={showHelp} onIonChange={e => {
          logActivity({id: 'help_toggled', shown: e.detail.checked}) 
          setShowHelp(e.detail.checked)
        }} />
      </IonItem>
      <IonItemDivider color="secondary" >Species &amp; season</IonItemDivider>
      <IonRadioGroup value={scoreTaxa[0]} onIonChange={e => taxaChanged(e.detail.value)}>
        <TaxaOpts/>
      </IonRadioGroup>
      <IonItem lines='none' id='season-select'>
        <IonLabel>Month</IonLabel>
        <IonSelect value={month} onIonChange={(e) => monthChange(e.detail.value)}>
          <IonSelectOption value="all_year">All months</IonSelectOption>
          <IonSelectOption value="jan" disabled={months.indexOf('jan') === -1}>January</IonSelectOption>
          <IonSelectOption value="feb" disabled={months.indexOf('feb') === -1}>February</IonSelectOption>
          <IonSelectOption value="mar" disabled={months.indexOf('mar') === -1}>March</IonSelectOption>
          <IonSelectOption value="apr" disabled={months.indexOf('apr') === -1}>April</IonSelectOption>
          <IonSelectOption value="may" disabled={months.indexOf('may') === -1}>May</IonSelectOption>
          <IonSelectOption value="jun" disabled={months.indexOf('jun') === -1}>June</IonSelectOption>
          <IonSelectOption value="jul" disabled={months.indexOf('jul') === -1}>July</IonSelectOption>
          <IonSelectOption value="aug" disabled={months.indexOf('aug') === -1}>August</IonSelectOption>
          <IonSelectOption value="sep" disabled={months.indexOf('sep') === -1}>September</IonSelectOption>
          <IonSelectOption value="oct" disabled={months.indexOf('oct') === -1}>October</IonSelectOption>
          <IonSelectOption value="nov" disabled={months.indexOf('nov') === -1}>November</IonSelectOption>
          <IonSelectOption value="dec" disabled={months.indexOf('dec') === -1}>December</IonSelectOption>
        </IonSelect>
      </IonItem>
      <IonItemDivider color="secondary">DECIDE score layer</IonItemDivider>
      <IonItem lines='none' id='opacity-slider'>
        <IonLabel >Opacity:</IonLabel>
        <IonRange className='opacitySlider' min={0} max={1} step={0.1} value={opacityDecideScoreLayer} 
          onIonChange={e => setOpacityDecideScoreLayer(e.detail.value)} 
          onIonBlur={e => {
            // Logging opacity is done on blur event (focus leaves control)
            // Because if done on change, would be hundreds of log entries
            // as slider moved.
            logActivity({id: 'score_opacity_set', value: opacityDecideScoreLayer})
          }}
        />
      </IonItem>
      <IonItem lines='none' id='threshold-slider'>
        <IonLabel >Filter:</IonLabel>
        <IonRange disabled={useFogmap} className='opacitySlider' snaps={true} min={0.1} max={1} step={0.1} value={useFogmap ? 1 : 1-thresholdDecideScoreLayer} debounce={500} onIonChange={e => thresholdChange(e.detail.value)}>
          <IonLabel slot="end">{Math.round((1-thresholdDecideScoreLayer) * 1000)/10 + '%'}</IonLabel>
        </IonRange>
      </IonItem>
      <IonItem lines='none' id='fogmap-toggle'>
        <IonLabel>Fog map: {useFogmap ? 'on' : 'off'}</IonLabel>
        <IonToggle color="primary" checked={useFogmap} onIonChange={e => {
          setUseFogmap(e.detail.checked)
          setScoreImageUrl('fetch')
          // Reset opacity and legend colour
          if (e.detail.checked) {
            setOpacityDecideScoreLayer(1)
            setScoreColour('fog')
          } else {
            setOpacityDecideScoreLayer(0.6)
            setScoreColour('viridis_truncated')
          }
          // const on_off = e.detail.checked ? 'on' : 'off'
          // ReactGA.event({
          //   category: 'Decide score map',
          //   action: `Toggle fogmap ${on_off}`
          // })
        }} />
      </IonItem>

      <IonItemDivider color="secondary">DECIDE suggestions</IonItemDivider>
      <IonItem lines='none' id='max-suggestions'>
        <IonLabel >Maximum suggestions:</IonLabel>
        <IonInput type="number" max="50" value={maxNudges} onIonChange={e => setMaxNudges(Number(e.detail.value) > maximumNudges ? maximumNudges : e.detail.value)}></IonInput>
      </IonItem>

      <IonItemDivider color="secondary">Access layers</IonItemDivider>
      <IonItem lines='none' id='access-row'>
        <IonLabel>Rights of Way: {showRowAccess ? 'shown' : 'hidden'}</IonLabel>
        <IonToggle color="primary" checked={showRowAccess} onIonChange={e => {
          setShowRowAccess(e.detail.checked)
          if (e.detail.checked && !prowGeojson) {
            setProwGeojson("fetch")
          }
        }} />
      </IonItem>
      <IonItem lines='none' id='access-points'>
        <IonLabel>Access points: {showPointAccess ? 'shown' : 'hidden'}</IonLabel>
        <IonToggle color="primary" checked={showPointAccess} onIonChange={e => {
          setShowPointAccess(e.detail.checked)
          if (e.detail.checked && !accesspointGeojson) {
            setAccesspointGeojson("fetch")
          }
        }} />
      </IonItem>
      <IonItem lines='none' id='access-greenspace'>
        <IonLabel>Greenspace: {showGreenspaceAccess ? 'shown' : 'hidden'}</IonLabel>
        <IonToggle color="primary" checked={showGreenspaceAccess} onIonChange={e => {
          setShowGreenspaceAccess(e.detail.checked)
          if (e.detail.checked && !greenspaceGeojson) {
            setGreenspaceGeojson("fetch")
          }
        }} />
      </IonItem>
      <IonItem lines='none' id='access-open'>
        <IonLabel>Open access: {showOpenAccess ? 'shown' : 'hidden'}</IonLabel>
        <IonToggle color="primary" checked={showOpenAccess} onIonChange={e => {
          setShowOpenAccess(e.detail.checked)
          if (e.detail.checked && !accesslandGeojson) {
            setAccesslandGeojson("fetch")
          }
        }} />
      </IonItem>
      <IonItem lines='none' id='access-nt'>
        <IonLabel>National Trust: {showNtAccess ? 'shown' : 'hidden'}</IonLabel>
        <IonToggle color="primary" checked={showNtAccess} onIonChange={e => {
          setShowNtAccess(e.detail.checked)
          if (e.detail.checked && !ntGeojson) {
            setNtGeojson("fetch")
          }
        }} />
      </IonItem>
      <IonItem lines='none' id='access-london'>
        <IonLabel>London access: {showLondonAccess ? 'shown' : 'hidden'}</IonLabel>
        <IonToggle color="primary" checked={showLondonAccess} onIonChange={e => {
          setShowLondonAccess(e.detail.checked)
          if (e.detail.checked && !londonGeojson) {
            setLondonGeojson("fetch")
          }
        }} />
      </IonItem>

      {/* <IonItemDivider color="warning" >DEVEL only</IonItemDivider> */}
    </IonList>
  </>)
}
export default ScoreMapOpts

const TaxaOpts: React.FC = () => {

  const { configTaxa, setConfigTaxa} = useContext(AppContext)

  // Get taxa configuration
  if (configTaxa.length === 0) {
    let menu:string
    if (window.location.hostname.indexOf('staging') > -1 || window.location.hostname === 'localhost') {
      // On staging cluster and localhost, config comes from config_edit.
      menu = `${process.env.REACT_APP_DBAPI_URL}config_edit/taxa.txt`
    } else {
      menu = `${process.env.REACT_APP_DBAPI_URL}config/taxa.txt`
    }
    fetch(menu,
      {headers : { 
          'Content-Type': 'application/text',
          'Accept': 'application/text'
        }
      }).then(function(response){
        return response.text()
      }).then(function(text) {
        const txt = text.trim()
        const config:Array<any> = []
        txt.split('\n').forEach(line => {
          const splitLine = line.split(',')
          config.push({
            machine: splitLine[0].trim(),
            human: splitLine[1].trim(),
            months: splitLine[2].trim()
          })
        })
        setConfigTaxa(config)
      })
  }

  const opts = configTaxa.map(t => {
    return <IonItem lines='none' id={`taxa-${t.machine}`} key={`key-${t.machine}`}>
      <IonLabel>{`${t.human}`}</IonLabel>
      <IonRadio color="primary" value={`${t.machine}`} />
    </IonItem>
  })
  return (<>{opts}</>)
}