import { FunctionComponent, useContext } from 'react'
import { useMapEvents } from 'react-leaflet'
import AppContext from '../context/AppContext'
import ScoreContext from '../context/ScoreContext'
import { select } from 'd3-selection'
import { logActivity } from '../js/logapi.js' 
const circleToPolygon = require('circle-to-polygon')
const pointInPolygon = require('point-in-polygon')

const TrackMap: FunctionComponent = () => {

  const { setScoreEnabled, lat, lon } = useContext(ScoreContext)
  const { setMapLat, setMapLon, setMapZoom, radiusDecideScore } = useContext(AppContext)
  const { setClickLat, setClickLon, setNudgeJson, scoreImageUrl } = useContext(ScoreContext)

  const map = useMapEvents({
    moveend: () => {
      // Store map centre and zoom
      const centre = map.getCenter()
      setMapLat(centre.lat)
      setMapLon(centre.lng)
      setMapZoom(map.getZoom())
      //console.log(map.getZoom())
      // Enable score button
      setScoreEnabled(true)
      // Glow up the score button
      setTimeout(() => {
        // Needs to go in timeout in order for MapButton.tsx to
        // first take off the disabled class
        select('#decide-score-button').classed('enabled', true)
      }, 100);
    },
    click: async (e) => {

      //console.log(e.latlng.lat, e.latlng.lng)
      setClickLat(e.latlng.lat)
      setClickLon(e.latlng.lng)

      const gjsonC=circleToPolygon([lon, lat], radiusDecideScore, 128)
      if (pointInPolygon([e.latlng.lng, e.latlng.lat], gjsonC.coordinates[0])) {
        if (scoreImageUrl) {
          setNudgeJson('fetch')
        }
      }
    },
    mousemove: async (e) => {
      //setMouseLat(e.latlng.lat)
      //setMouseLon(e.latlng.lng)
    },
    baselayerchange: (e) => {
      logActivity({id: 'basemap_change', 'name': e.name})
      console.log(e.name)
    },
    popupopen: (e) => {
      // It was noticed prior to release of v1.0.1 that the popup close
      // button on leaflet popup is a link with href set to #close. In
      // dev environment (Google in Linux) clicking this causes the
      // relative link /#close to be followed which refreshed whole app.
      // The following modifies the link so that it doesn't do that.
      const a = document.querySelector('.leaflet-popup-close-button')
      if (a) {
        // eslint-disable-next-line no-script-url
        a.setAttribute('href', 'javascript:void(0)')
      }
    }
  })
  return null
}
export default TrackMap