import { useEffect, useState, useContext } from 'react'
import AppContext from '../context/AppContext'
import './GrControl.css'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { getGr } from './bigr'
import { logActivity } from '../js/logapi.js' 

// This is not typescript because I couldn't find out how to extend Leaflet
// control using typescript

const GrControl = ({map, position, render, clickLat, clickLon, setShowGrSquare, showGrSquare, setGrSquare} ) => {

  // The render argument is simply there to force this control
  // to re-render whenever one of the buttons above it may
  // have changed otherwise their position is swapped.

  const [precision, setPrecision] = useState('p10000')
  const [display, setDisplay] = useState('small')
  const { setHelpIdentifier} = useContext(AppContext)

  useEffect(() => {

    const grs = getGr(clickLat, clickLon)
    const checked = showGrSquare ? 'checked' : ''
    const hectad = grs['p10000'] ? grs['p10000'] : ''
    const quadrant = grs['p5000'] ? grs['p5000'] : ''
    const tetrad = grs['p2000'] ? grs['p2000'] : ''
    const monad = grs['p1000'] ? grs['p1000'] : ''
    const sixfig = grs['p100'] ? grs['p100'] : ''
    const eightfig = grs['p10'] ? grs['p10'] : ''

    setGrSquare(grs[precision])
    
    L.Control.GridRef = L.Control.extend({
      onAdd: function() {

        var div = L.DomUtil.create('div')
        div.id ='react-grid-ref-control'

        const control = `<div>
          
          <div id="react-grid-ref-makesmall">X</div>

          <div id="react-grid-ref-check-and-label">
            <input type="checkbox" id="react-grid-ref-check" ${checked}>
            <label for="react-grid-ref-check">Show square</label>
          </div>

          <div id='react-grid-ref-grs'>
            <div class='react-grid-ref' data-precision='p10000'>
              <span class='react-grid-ref-label'>10 km:</span>
              <span>${hectad}</span>
            </div>
            <div class='react-grid-ref' data-precision='p5000'>
              <span class='react-grid-ref-label'>5 km:</span>
              <span>${quadrant}</span>
            </div>
            <div class='react-grid-ref' data-precision='p2000'>
              <span class='react-grid-ref-label'>2 km:</span>
              <span>${tetrad}</span>
            </div>
            <div class='react-grid-ref' data-precision='p1000'>
              <span class='react-grid-ref-label'>1 km:</span>
              <span>${monad}</span>
            </div>
            <div class='react-grid-ref' data-precision='p100'>
              <span class='react-grid-ref-label'>100 m:</span>
              <span>${sixfig}</span>
            </div>
            <div class='react-grid-ref' data-precision='p10'>
              <span class='react-grid-ref-label'>10 m:</span>
              <span>${eightfig}</span>
            </div>
          </div>
        </div>`
        div.innerHTML  = control
        return div
      },
      onRemove: function() {
        //console.log('Remove control')
        // Nothing to do here
      }
    })
    
    L.control.gridRef = function(opts) {
      return new L.Control.GridRef(opts);
    }
    
    const cc = L.control.gridRef({ position: position }).addTo(map);

    // Select div element matched current precision to selected class
    const selected = document.querySelector(`.react-grid-ref[data-precision="${precision}"]`)
    selected.classList.add('selected')

    // Make small button
    const dismiss = document.getElementById('react-grid-ref-makesmall')
    dismiss.onclick = e => {
      logActivity({id: 'gr_control', interaction: 'display_changed', value: 'minimised'})
      setDisplay('small')
    }

    // Set display class
    const ctl = document.getElementById('react-grid-ref-control')
    ctl.classList.add(display)
    // Event hanlders
    ctl.onclick = e => {
      setHelpIdentifier(`custom-control#react-grid-ref-control`)
      e.stopPropagation()
    }
    ctl.onmouseover = e => {
      setHelpIdentifier(`custom-control#react-grid-ref-control`)
    }

    // Set event handler on checkbox
    const cb = document.getElementById('react-grid-ref-check')
    cb.onclick = e => { 
      logActivity({id: 'gr_control', interaction: 'square_visible', value: cb.checked})
      setShowGrSquare(cb.checked)
    }

    var grdivs = document.getElementsByClassName('react-grid-ref')
    Array.from(grdivs).forEach(div => {
      const divPrecision = div.getAttribute('data-precision')
      div.onclick = e => {
        if (precision !== divPrecision) {
          logActivity({id: 'gr_control', interaction: 'precision_changed', value: divPrecision.substring(1)})
        }
        if (display === 'small') {
          logActivity({id: 'gr_control', 'display_changed': 'full'})
        }
        // Set current precision
        setPrecision(divPrecision)
        // Set displayed GR
        setGrSquare(grs[divPrecision])
        // Make the display large
        setDisplay('large')
      }
    })

    return () => {
      map.removeControl(cc)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render, clickLat, clickLon, setShowGrSquare, showGrSquare, setGrSquare, display])
  return null
}
export default GrControl
