import React from 'react';

interface Score {
  scoreImageUrl: string;
  setScoreImageUrl: any;
  nudgesJson: string;
  setNudgesJson: any;
  nudgeJson: string;
  setNudgeJson: any;
  singleNudgesJson: string;
  setSingleNudgesJson: any;
  prowGeojson: string;
  setProwGeojson: any;
  greenspaceGeojson: string;
  setGreenspaceGeojson: any;
  accesspointGeojson: string;
  setAccesspointGeojson: any;
  accesslandGeojson: string;
  setAccesslandGeojson: any;
  ntGeojson: string;
  setNtGeojson: any;
  londonGeojson: string;
  setLondonGeojson: any;
  radius: number;
  setRadius: any
  lat: number;
  setLat: any;
  lon: number;
  setLon: any;
  scoreEnabled: boolean;
  setScoreEnabled: any;
  devOpts: any;
  setDevOpts: any;
  clickLat: number;
  setClickLat: any;
  clickLon: number;
  setClickLon: any;
  selectedHtml: string;
  setSelectedHtml: any;
  selectedFtr: any;
  setSelectedFtr: any;
  selectedFtrMetadata: any;
  setSelectedFtrMetadata: any;
  useFogmap: boolean;
  setUseFogmap: any;
  month: string;
  setMonth: any;
}
const ScoreContext = React.createContext<Score>({
  scoreImageUrl: '',
  setScoreImageUrl: null,
  nudgesJson: '',
  setNudgesJson: null,
  nudgeJson: '',
  setNudgeJson: null,
  singleNudgesJson: '',
  setSingleNudgesJson: null,
  prowGeojson: '',
  setProwGeojson: null,
  greenspaceGeojson: '',
  setGreenspaceGeojson: null,
  accesspointGeojson: '',
  setAccesspointGeojson: null,
  accesslandGeojson: '',
  setAccesslandGeojson: null,
  ntGeojson: '',
  setNtGeojson: null,
  londonGeojson: '',
  setLondonGeojson: null,
  radius: 0,
  setRadius: null,
  lat: 0,
  setLat: null,
  lon: 0,
  setLon: null,
  scoreEnabled: true,
  setScoreEnabled: null,
  devOpts: {},
  setDevOpts: null,
  clickLat: 0,
  setClickLat: null,
  clickLon: 0,
  setClickLon: null,
  selectedHtml: '',
  setSelectedHtml: null,
  selectedFtr: {},
  setSelectedFtr: null,
  selectedFtrMetadata: {},
  setSelectedFtrMetadata: null,
  useFogmap: false,
  setUseFogmap: null,
  month: 'any',
  setMonth: null
})
export default ScoreContext