//import { checkGr, getGrFromCoords, getCentroid, getGjson } from 'brc-atlas-bigr' 
import { checkGr, getGrFromCoords, getCentroid, getGjson } from '../js/bigr.esm.js' 

export function getGr(lat, lng) {
  return getGrFromCoords(lng, lat, 'wg', '', [100000, 10000, 5000, 2000, 1000, 100, 10, 1])
}

export function checkGridRef(gr) {
  return checkGr(gr)
}

export function getGrCentroid(gr) {
  return getCentroid(gr, 'wg')
}

export function getSquareGjson(gr) {
  return getGjson(gr, 'wg', 'square', 1)
}