import React, { useEffect, FunctionComponent, useContext } from 'react'
import AppContext from '../context/AppContext'
import './MapButton.css'
import { renderToString } from 'react-dom/server'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-easybutton'

interface MapButtonProps {
  map: any,
  position?: "topleft" | "topright" | "bottomleft" | "bottomright" | undefined,
  width?: number,
  height?: number,
  title?: string,
  onClick?: Function,
  id?: string,
  enabled?: boolean,
  render?: number
}
const MapButton: FunctionComponent<MapButtonProps> = ({ children, map, position, width=170, height=27, title, onClick, id, enabled=true, render=0 }) => {

  const { setHelpIdentifier} = useContext(AppContext)

  useEffect(() => {

    //console.log('enabled', enabled)
    //console.log('onclick', onClick)

    //console.log('rendering button', id)
    //console.log('enabled', enabled)

    const handleClick = () => {
      setHelp(id)
      if (onClick) {
        onClick()
      }
    }
    const setHelp = (id:string|undefined) => {
      setHelpIdentifier(`map-button#${id}`)
    }
    
    const button = L.easyButton ({
      position: position ? position : 'topleft',
      states: [{
        stateName: '',
        title: title ? title: '',
        icon: renderToString(<div className="mapButton">{children}</div>),
        onClick: handleClick,
      }],
      id: id ? id : ''
    })
    if (enabled) {
      //button.enable()
    } else {
      button.disable()
    }
    // @ts-ignore: property button does not exist on type Any 
    button.button.style.width = `${width}px`
    // @ts-ignore: property button does not exist on type Any 
    button.button.style.height = `${height}px`
    // @ts-ignore: property button does not exist on type Any 
    button.button.style.lineHeight = `${height}px`

    button.addTo(map)

    // Add mouseenter event handler for help system
    const ctl = document.getElementById(id ? id : '') as HTMLElement
    ctl.onmouseover = e => {
      setHelp(id)
    }

    return () => {
      // Cleanup
      map.removeControl(button)
      //console.log('remove map button')
    }
  // The onClick function must not be in the array below that governs when
  // the button is regenerated. If it is, the button is always regenerated
  // reglardless of whether or not the onClick function changees (something to 
  // do with it being an object?). For buttons where the onClick function does
  // change, signal the change by setting the refresh property to a random number.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, id, render]) //onClick
  return null
}
export default MapButton
