import React, { useContext, useEffect, useState } from 'react'
import { IonIcon, IonPage } from '@ionic/react'
import { MapContainer, MapConsumer, Polygon } from 'react-leaflet'
import { locateOutline, locateSharp} from 'ionicons/icons'
import { pinOutline, pinSharp} from 'ionicons/icons'
import './ScoreMap.css'
import StandardLayers from '../components/StandardLayers'
import StandardMapControls from '../components/StandardMapControls'
import MapButton from '../components/MapButton'
import ScoreTargetCircle from '../components/ScoreTargetCircle'
import ScoreOverlay from '../components/ScoreOverlay'
import AccessGeojsonLayers from '../components/AccessGeojsonLayers'
import NudgeLayer from '../components/NudgeLayer'
import FindPlacePopover from '../components/FindPlacePopover'
import ScoreContext from '../context/ScoreContext'
import AppContext from '../context/AppContext'
import DecideScoreLegend from '../components/DecideScoreLegend.js'
import GrControl from '../components/GrControl.js'
import { getSquareGjson } from '../components/bigr'
import TrackMap from '../components/TrackMap'
import HelpPanel from '../components/HelpPanel'
import iconInfoBlue from '../images/info-icon-blue.png'
import PageTabs from '../components/PageTabs'
import SpinnerCentre from '../components/SpinnerCentre'
//import ReactGA from '../components/ReactGAProxy'
import { logLocation } from '../js/location'

import Track from '../components/Track'

const ScoreMap = () => {

  const { setTool, showRowAccess, showPointAccess, showGreenspaceAccess,
    showOpenAccess, showNtAccess, showLondonAccess,
    mapLat, mapLon, mapZoom, showHelp, setHelpIdentifier } = useContext(AppContext)

  const { clickLat, clickLon, setLat, setLon, setScoreImageUrl, scoreImageUrl, setProwGeojson, setNudgesJson, setSingleNudgesJson,
    setGreenspaceGeojson, setAccesspointGeojson, setAccesslandGeojson,
    setNtGeojson, setLondonGeojson, setScoreEnabled, scoreEnabled, setSelectedFtr} = useContext(ScoreContext)

  useEffect(() => {
    // This has to be done in useEffect (after mounted) to 
    // avoid 'cannot update a component while rendering a 
    // different component' warning .
    setTool('scoremap')

    // Event on leaflet controls for help system
    const ctls = ['zoom-in', 'zoom-out', 'layers-toggle', 'layers-list', 'scale']
    ctls.forEach((c) => {
      let ctl = document.getElementsByClassName(`leaflet-control-${c}`)[0]
      if (ctl) {
        ctl.onclick = e => {
          setHelpIdentifier(`leaflet-control#${c}`)
        }
        ctl.onmouseover = e => {
          setHelpIdentifier(`leaflet-control#${c}`)
        }
      }
    })
  })

  const [showFindPlace, setShowFindPlace] = useState(false) 
  const [showGrSquare, setShowGrSquare] = useState(false) 
  const [grSquare, setGrSquare] = useState('')

  // Coordinates for GR square display
  let positions = [[0,0]]
  if (showGrSquare && grSquare) {
    const gjson = getSquareGjson(grSquare)
    positions = gjson.coordinates[0].map((ll) => {
      return [ll[1], ll[0]]
    })
  }

  return (<IonPage>
    <Track/>
    <PageTabs/>
    <div style={{height: '100%'}}>
      <div id="map-container-container"
        className={showHelp ? 'show-help' : 'hide-help'}
      >
        <MapContainer
          // MapContainer properties are immutable once created
          // boundsOptions={{padding: [50, 50]}} 
          id='decidescoremap'
          preferCanvas={false} // Needs to be set to false if we are using SVG patterns for objects on map
          zoomControl={false}
          attributionControl
          // Centre and zoom to show whole of GB
          center={{ lat: mapLat, lng: mapLon }}
          zoom={mapZoom}
        >
          <SpinnerCentre/>
          <MapConsumer>
            {(map) => {
              return (<>
                <TrackMap/>
                <StandardLayers/>
                <Polygon 
                  positions={positions}
                  fillOpacity={0}
                  pathOptions={{ color: 'black', weight: 1 }} 
                />
                <ScoreTargetCircle map={map} radius={5000}/>
                <ScoreOverlay map={map} />
                <AccessGeojsonLayers/>
                <NudgeLayer />
                <FindPlacePopover showFindPlace={showFindPlace} setShowFindPlace={setShowFindPlace} map={map} />
                <MapButton id='current-location-button' map={map} width={30} height={30} position='topright' title='Zoom to current location'
                  onClick={function() {
                    // ReactGA.event({
                    //   category: 'Decide score map',
                    //   action: 'Centre map on current location'
                    // })
                    // The Leaflet map.locate method takes care of centering map on
                    // user's current location so we need an independent method to
                    // log this so that user's geolocation can be logged. This is done
                    // by the logLocation function.
                    logLocation()
                    map.locate({setView: true, maxZoom: 16, enableHighAccuracy: true})
                  }}>
                  <IonIcon style={{pointerEvents: 'none'}} slot="start" ios={locateOutline} md={locateSharp}  />
                </MapButton>
                <MapButton id='find-place-button' map={map} width={30} height={30} position='topright' title='Find place by GR or name'
                  onClick={function() {
                    // ReactGA.event({
                    //   category: 'Decide score map',
                    //   action: 'Find place by GR or name'
                    // })
                    setShowFindPlace(true)
                  }}>
                  <IonIcon style={{pointerEvents: 'none'}} slot="start" ios={pinOutline} md={pinSharp}  />
                </MapButton>
                <MapButton id='decide-score-button' map={map} position='topleft' title='Decide score' enabled={scoreEnabled}
                  onClick={() => {
                    setLat(mapLat)
                    setLon(mapLon)
                    setScoreImageUrl("fetch")
                    setSingleNudgesJson("")
                    setNudgesJson("")
                    setSelectedFtr({})
                    setProwGeojson(showRowAccess ? "fetch" : "")
                    setGreenspaceGeojson(showGreenspaceAccess ? "fetch" : "")
                    setAccesspointGeojson(showPointAccess ? "fetch" : "")
                    setAccesslandGeojson(showOpenAccess ? "fetch" : "")
                    setNtGeojson(showNtAccess ? "fetch" : "")
                    setLondonGeojson(showLondonAccess ? "fetch" : "")
                    setScoreEnabled(false)
                  }}
                  //Ensure that this button is always refreshed if any of the relevant state
                  //changes. The onClick property itself is ignored if it changes (see MapButton def)
                  render={Math.random()} 
                >
                  Create DECIDE map
                </MapButton>
                <MapButton id='decide-nudge-button' map={map} position='topleft' title='Decide suggestions' 
                  enabled={scoreImageUrl==="" || scoreImageUrl==="fetch" ? false : true}
                  onClick={() => {
                    setSingleNudgesJson("")
                    setNudgesJson("fetch")
                  }}
                  //Ensure that this button is always refreshed if any of the relevant state
                  //changes. The onClick property itself is ignored if it changes (see MapButton def)
                  render={Math.random()} 
                >
                  <span style={{verticalAlign: 'top', marginRight: '0.3em'}}>Get suggestions</span>
                  <img alt='' src={iconInfoBlue} style={{height: '23px', marginTop: '1px'}}></img>
                </MapButton>
                <GrControl map={map} position="bottomright" render={Math.random()} clickLat={clickLat} clickLon={clickLon} setShowGrSquare={setShowGrSquare} showGrSquare={showGrSquare} setGrSquare={setGrSquare}/>
                {/* <GrControl map={map} position="bottomright" render={Math.random()} clickLat={mouseLat} clickLon={mouseLon} setShowGrSquare={setShowGrSquare} showGrSquare={showGrSquare} setGrSquare={setGrSquare}/> */}
                <DecideScoreLegend map={map} position="topleft" render={Math.random()}/>
                <StandardMapControls map={map} />
              </>)
            }}
          </MapConsumer>
        </MapContainer>
      </div>
      <HelpPanel/>
    </div>
  </IonPage>)
}
export default ScoreMap
