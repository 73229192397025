import React, { FunctionComponent, useContext } from 'react'
import ScoreContext from '../context/ScoreContext'
import L from 'leaflet'
import AppContext from '../context/AppContext'
import AccessGeojsonLayer from './AccessGeojsonLayer'
import './AccessGeojsonLayers.css'

//import { IonPopover, IonButton, IonToolbar } from '@ionic/react'

interface AccessGeojsonProps {
}

const AccessGeojsonLayers: FunctionComponent<AccessGeojsonProps> = () => {
  const { 
    prowGeojson,
    setProwGeojson,
    greenspaceGeojson,
    setGreenspaceGeojson,
    accesspointGeojson,
    setAccesspointGeojson,
    accesslandGeojson,
    setAccesslandGeojson,
    ntGeojson,
    setNtGeojson,
    londonGeojson,
    setLondonGeojson
  } = useContext(ScoreContext)
  const { showRowAccess, showPointAccess, showGreenspaceAccess, showOpenAccess, showNtAccess, showLondonAccess} = useContext(AppContext)

  //const [showPopover, setShowPopover] = useState(false)
  //const [popoverHtml, setPopoverHtml] = useState(<></>)

  return (<>

    {/* <IonPopover
      cssClass='accessPopover'
      isOpen={showPopover}
      onDidDismiss={() => setShowPopover(false)}
    >
      <div style={{margin: '0.4em', fontSize: '0.9em'}}>
        {popoverHtml}
        <IonToolbar style={{minHeight: '0px'}}>
          <IonButton slot="end" size='small' onClick={() => setShowPopover(false)}>Close</IonButton>
        </IonToolbar>
      </div>
    </IonPopover> */}

    <AccessGeojsonLayer type='accessland'
      display={showOpenAccess}
      //zIndex={497}
      lkey={Math.random()} // Key is always changed (random number) to ensure correct ordering of geojson layers
      geojson={accesslandGeojson}
      setGeojson={setAccesslandGeojson}
      pathOpts={{
        color: '#AAAAAA'
      }}
      pointToLayer={null}
      onEachFeature={function(feature: any, layer: any) {
        let html = '<b>Open access</b><br/>'
        if (feature.properties['Descrip']) {
          //html = `${html}<b>Description:</b> ${feature.properties['Descrip']}`
        }
        layer.bindPopup(html)
      }}
    />
    <AccessGeojsonLayer type='greenspace'
      display={showGreenspaceAccess}
      //zIndex={498}
      lkey={Math.random()}
      geojson={greenspaceGeojson}
      setGeojson={setGreenspaceGeojson}
      pathOpts={{
        color: '#888888'
      }}
      pointToLayer={null}
      onEachFeature={function(feature: any, layer: any) {
        let html = '<b>Greenspace</b><br/>'
        if (feature.properties['function_']) {
          html = `${html}<b>Function:</b> ${feature.properties['function_']}<br/>`
        }
        if (feature.properties['distName1']) {
          html = `${html}<b>District:</b> ${feature.properties['distName1']}`
        }
        layer.bindPopup(html)
      }}
    />
    <AccessGeojsonLayer type='nattrust'
      display={showNtAccess}
      //zIndex={499}
      lkey={Math.random()}
      geojson={ntGeojson}
      setGeojson={setNtGeojson}
      pathOpts={{
        color: '#yellow'
      }}
      pointToLayer={null}
      onEachFeature={function(feature: any, layer: any) {
        let html = '<b>National Trust property</b><br/>'
        if (feature.properties['fclass']) {
          html = `${html}<b>Name:</b> ${feature.properties['Name']}`
        }
        if (feature.properties['trust_type']) {
          html = `${html}<b>Type:</b> ${feature.properties['trust_type'].replace('_', ' ')}`
        }
        layer.bindPopup(html)
      }}
    />
    <AccessGeojsonLayer type='prow'
      display={showRowAccess}
      //zIndex={500}
      lkey={Math.random()}
      geojson={prowGeojson}
      setGeojson={setProwGeojson}
      pathOpts={{
        color: '#666666'
      }}
      pointToLayer={null}
      onEachFeature={function(feature: any, layer: any) {
        let html = '<b>Public right of way</b><br/>'
        if (feature.properties['ROW_TYPE']) {
          html = `${html}<b>Type:</b> ${feature.properties['ROW_TYPE']}<br/>`
        }
        layer.bindPopup(html)
      }}
    />
    <AccessGeojsonLayer type='london'
      display={showLondonAccess}
      //zIndex={501}
      lkey={Math.random()}
      geojson={londonGeojson}
      setGeojson={setLondonGeojson}
      pathOpts={{
        color: '#666666'
      }}
      pointToLayer={null}
      onEachFeature={function(feature: any, layer: any) {
        let html = '<b>Right of way</b><br/>'
        if (feature.properties['fclass']) {
          html = `${html}<b>Type:</b> ${feature.properties['fclass']}`
        }
        layer.bindPopup(html)
      }}
    />
    <AccessGeojsonLayer type='accesspoint'
      display={showPointAccess}
      //zIndex={600}
      lkey={Math.random()}
      geojson={accesspointGeojson}
      setGeojson={setAccesspointGeojson}
      pathOpts={null}
      pointToLayer={function (feature: any, latlng: any) {
        return L.circleMarker(
          latlng, 
          {
            //pane: `access-pane-accesspoint`,
            radius: 4,
            fillColor: "#ff7800",
            color: "#000",
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8
          }
        )
      }}
      onEachFeature={function(feature: any, layer: any) {
        let html = '<b>Point of access</b><br/>'
        if (feature.properties['accessType']) {
          html = `${html}<b>Type:</b> ${feature.properties['accessType']}`
        }
        layer.bindPopup(html)

        // const ex1 = <div>
        //   <p>This is an excellent area to record data from.</p>
        //   <p><b>What to Expect?</b></p>
        //   <p>
        //     This site is a mixture of broadleaved woodland, supralittoral
        //     rock and suburban area.
        //   </p>
        //   <p>
        //     Our model predicts that this is a species rich site and there are
        //     likely to be 7 species present here.
        //   </p>
        //   <p><b>Why do we need data from this site?</b></p>
        //   <p>
        //     Our model is still uncertain about some species being present
        //     here, so further data could be useful.
        //   </p>
        //   <p>We haven't received records from this site since 2 years and 1 month ago.</p>
        // </div>

        // const ex2 = <div>
        //   <p>This is an excellent area for recording.</p>
        //   <p><b>What to Expect?</b></p>
        //   <p>
        //     This site is a mixture of broadleaved woodland, supralittoral
        //     rock and suburban area.
        //   </p>
        //   <p>
        //     Our model predicts that there are many species present here.
        //   </p>
        //   <p><b>Why do we need data from this site?</b></p>
        //   <p>
        //     Our model is still uncertain about some species being present
        //     here, so further data could be useful.
        //   </p>
        //   <p>We haven't received records from here for over 2 years.</p>
        // </div>

        // const ex3 = <div>
        //   <p>This location has a Decide Score of 9, making it a very useful site to record data from.</p>
        //   <p><b>What to Expect?</b></p>
        //   <p>
        //     This site is predominantly broadleaved woodland, but also contains supralittoral
        //     rock and suburban area.
        //   </p>
        //   <p>
        //     Our species distribution modelling predicts that this is a species rich 
        //     site, with 7 species predicted to be present here with high likelihood 
        //     and a further 6 possibly being present with lower likelihood.
        //   </p>
        //   <p><b>Why do we need data from this site?</b></p>
        //   <p>
        //     Our model reports high uncertainty bars around 3 of the species 
        //     predicted to be present with high likelihood, and confirmation of these 
        //     and the species predicted with lower likelihood would help improve our modelling.
        //   </p>
        //   <p>We haven't received records from this site since 2 years and 1 month ago.</p>
        // </div>

        // const jsx = <div>{feature.properties['accessType']}</div>
        // layer.on('click' , function() {
        //   setPopoverHtml(ex3)
        //   setShowPopover(true)
        // })
      }}
    />
  </>) 
}
export default AccessGeojsonLayers
