import React from 'react';

interface App {
  accessLevel: string;
  setAccessLevel: any;
  tool: string;
  setTool: any;
  path: string;
  setPath: any;
  showOpts: boolean;
  setShowOpts: any;
  showNudges: boolean;
  setShowNudges: any;
  maxNudges: number;
  setMaxNudges: any;
  showRowAccess: boolean;
  setShowRowAccess: any;
  showPointAccess: boolean;
  setShowPointAccess: any;
  showGreenspaceAccess: boolean;
  setShowGreenspaceAccess: any;
  showOpenAccess: boolean;
  setShowOpenAccess: any;
  showNtAccess: boolean;
  setShowNtAccess: any;
  showLondonAccess: boolean;
  setShowLondonAccess: any;
  showDecideScoreLayer: boolean;
  setShowDecideScoreLayer: any;
  opacityDecideScoreLayer: number;
  setOpacityDecideScoreLayer: any;
  scaleDecideScoreLayer: boolean;
  setScaleDecideScoreLayer: any;
  thresholdDecideScoreLayer: number;
  setThresholdDecideScoreLayer: any;
  showDecideScoreTarget: boolean;
  setShowDecideScoreTarget: any;
  radiusDecideScore: number;
  setRadiusDecideScore: any;
  scoreTaxa: Array<string>;
  setScoreTaxa: any;
  scoreColour: string;
  setScoreColour: any;
  radiusMin: number;
  radiusMax: number;
  surveyLink: string;
  busy: boolean;
  setBusy: any;
  mapLat: number;
  setMapLat: any;
  mapLon: number;
  setMapLon: any;
  mapZoom: number;
  setMapZoom: any;
  blogs: Array<any>;
  showHelp: boolean;
  setShowHelp: any;
  helpIdentifier: string;
  setHelpIdentifier: any;
  mouseLat: number;
  setMouseLat: any;
  mouseLon: number;
  setMouseLon: any;
  configTaxa: Array<any>;
  setConfigTaxa: any;
  showWalkthrough: boolean;
  setShowWalkthrough: any;
}

const AppContext = React.createContext<App>({
  accessLevel: '',
  setAccessLevel: null,
  tool: '',
  setTool: null,
  path: '',
  setPath: null,
  showOpts: false,
  setShowOpts: null,
  showNudges: true,
  setShowNudges: null,
  maxNudges: 0,
  setMaxNudges: null,
  showRowAccess: true,
  setShowRowAccess: null,
  showPointAccess: true,
  setShowPointAccess: null,
  showGreenspaceAccess: true,
  setShowGreenspaceAccess: null,
  showOpenAccess: true,
  setShowOpenAccess: null,
  showNtAccess: false,
  setShowNtAccess: null,
  showLondonAccess: false,
  setShowLondonAccess: null,
  showDecideScoreLayer: true,
  setShowDecideScoreLayer: null,
  opacityDecideScoreLayer: 0,
  setOpacityDecideScoreLayer: null,
  scaleDecideScoreLayer: true,
  setScaleDecideScoreLayer: null,
  thresholdDecideScoreLayer: 0,
  setThresholdDecideScoreLayer: null,
  showDecideScoreTarget: true,
  setShowDecideScoreTarget: null,
  radiusDecideScore: 0,
  setRadiusDecideScore: null,
  scoreTaxa: [],
  setScoreTaxa: null,
  scoreColour: '',
  setScoreColour: null,
  radiusMin: 0,
  radiusMax: 0,
  surveyLink: '',
  busy: false,
  setBusy: null,
  mapLat: 0,
  setMapLat: null,
  mapLon: 0,
  setMapLon: null,
  mapZoom: 0,
  setMapZoom: null,
  blogs: [],
  showHelp: false,
  setShowHelp: null,
  helpIdentifier: '',
  setHelpIdentifier: null,
  mouseLat: 0,
  setMouseLat: null,
  mouseLon: 0,
  setMouseLon: null,
  configTaxa: [],
  setConfigTaxa: null,
  showWalkthrough: true,
  setShowWalkthrough: null,
});

export default AppContext;