import { selectAll } from 'd3-selection'

export const setNudgeSvgStyles = () => {

  const defs = selectAll('.leaflet-overlay-pane svg').append('defs')

  // Hatched fill
  // Change pattern width attr to change gap between lines
  // Change line stroke-width attr to change thickness of lines
  // Change line stroke to change colour of hatch
  const hatch1 = defs.append('pattern')
  hatch1.attr('id', 'hatch1')
  hatch1.attr('width', 5)
  hatch1.attr('height', 10)
  hatch1.attr('patternTransform', 'rotate(45 0 0)')
  hatch1.attr('patternUnits', 'userSpaceOnUse')
  const line1 = hatch1.append('line')
  line1.attr('x1', 0)
  line1.attr('y1', 0)
  line1.attr('x2', 0)
  line1.attr('y2', 10)
  line1.style('stroke', 'black')
  line1.style('stroke-width', 2)

  // Hatched fill - blue
  const hatch2 = defs.append('pattern')
  hatch2.attr('id', 'hatch2')
  hatch2.attr('width', 5)
  hatch2.attr('height', 10)
  hatch2.attr('patternTransform', 'rotate(45 0 0)')
  hatch2.attr('patternUnits', 'userSpaceOnUse')
  const line2 = hatch2.append('line')
  line2.attr('x1', 0)
  line2.attr('y1', 0)
  line2.attr('x2', 0)
  line2.attr('y2', 10)
  line2.style('stroke', 'blue')
  line2.style('stroke-width', 2)

  // Radial gradient fill - red circle fade 
  const radgrad0 = defs.append('radialGradient')
  radgrad0.attr('id', 'radgrad0')
  const rg0s1 = radgrad0.append('stop')
  const rg0s2 = radgrad0.append('stop')
  rg0s1.attr('offset', '0%')
  rg0s1.attr('stop-color', 'red')
  rg0s1.attr('stop-opacity', '1')
  rg0s2.attr('offset', '100%')
  rg0s2.attr('stop-color', 'red')
  rg0s2.attr('stop-opacity', '0')

  // Radial gradient fill - blue circle fade
  const radgrad1 = defs.append('radialGradient')
  radgrad1.attr('id', 'radgrad1')
  const rg1s1 = radgrad1.append('stop')
  const rg1s2 = radgrad1.append('stop')
  rg1s1.attr('offset', '0%')
  rg1s1.attr('stop-color', 'blue')
  rg1s1.attr('stop-opacity', '1')
  rg1s2.attr('offset', '100%')
  rg1s2.attr('stop-color', 'blue')
  rg1s2.attr('stop-opacity', '0')

  // Radial ring gradient fill - blue ring
  const radgrad2 = defs.append('radialGradient')
  radgrad2.attr('id', 'radgrad2')
  const rg2s1 = radgrad2.append('stop')
  const rg2s2 = radgrad2.append('stop')
  const rg2s3 = radgrad2.append('stop')
  const rg2s4 = radgrad2.append('stop')
  rg2s1.attr('offset', '0%')
  rg2s1.attr('stop-color', '#2981CA')
  rg2s1.attr('stop-opacity', '0')
  rg2s2.attr('offset', '80%')
  rg2s2.attr('stop-color', '#2981CA')
  rg2s2.attr('stop-opacity', '0')
  rg2s3.attr('offset', '90%')
  rg2s3.attr('stop-color', '#2981CA')
  rg2s3.attr('stop-opacity', '0.6')
  rg2s4.attr('offset', '100%')
  rg2s4.attr('stop-color', '#2981CA')
  rg2s4.attr('stop-opacity', '0')

  // Radial ring gradient fill - red ring
  const radgrad3 = defs.append('radialGradient')
  radgrad3.attr('id', 'radgrad3')
  const rg3s1 = radgrad3.append('stop')
  const rg3s2 = radgrad3.append('stop')
  const rg3s3 = radgrad3.append('stop')
  const rg3s4 = radgrad3.append('stop')
  rg3s1.attr('offset', '0%')
  rg3s1.attr('stop-color', 'red')
  rg3s1.attr('stop-opacity', '0')
  rg3s2.attr('offset', '80%')
  rg3s2.attr('stop-color', 'red')
  rg3s2.attr('stop-opacity', '0')
  rg3s3.attr('offset', '90%')
  rg3s3.attr('stop-color', 'red')
  rg3s3.attr('stop-opacity', '0.6')
  rg3s4.attr('offset', '100%')
  rg3s4.attr('stop-color', 'red')
  rg3s4.attr('stop-opacity', '0')

  // Radial ring gradient fill - grey ring
  const radgrad4 = defs.append('radialGradient')
  radgrad4.attr('id', 'radgrad4')
  const rg4s1 = radgrad4.append('stop')
  const rg4s2 = radgrad4.append('stop')
  const rg4s3 = radgrad4.append('stop')
  const rg4s4 = radgrad4.append('stop')
  rg4s1.attr('offset', '0%')
  rg4s1.attr('stop-color', 'grey')
  rg4s1.attr('stop-opacity', '0')
  rg4s2.attr('offset', '80%')
  rg4s2.attr('stop-color', 'grey')
  rg4s2.attr('stop-opacity', '0')
  rg4s3.attr('offset', '90%')
  rg4s3.attr('stop-color', 'grey')
  rg4s3.attr('stop-opacity', '0.6')
  rg4s4.attr('offset', '100%')
  rg4s4.attr('stop-color', 'grey')
  rg4s4.attr('stop-opacity', '0')
}
