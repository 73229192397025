import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from "react";
import Iframe from 'react-iframe'
import Track from '../components/Track'

const Page: React.FC = () => {

  const newsletterUrl = 'https://connect-apps.ceh.ac.uk/decide-newsletter/'

  return (
    <IonPage>
      <Track/>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>MyDECIDE newsletter</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
      <Iframe url={newsletterUrl}
        width='100%'
        height='100%'
      />
      </IonContent>
    </IonPage>
  );
};

export default Page;
