import { IonLabel, IonSpinner, IonItem, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useContext} from "react";
import Track from '../components/Track'
import AppContext from '../context/AppContext'

const Page: React.FC = () => {

  const { blogs } = useContext(AppContext)
  const blogUrl = 'https://decidenature.wordpress.com/blog'

  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } })
  let blogsHtml
  if (blogs.length > 0) {
    blogsHtml = blogs.map((blog: any, i: number) => {
      // return <IonItem key={`blog${i}`} lines="full"> 
      //   <a href={blog.url} rel="noreferrer" target="_blank">{blog.title.replace(/&nbsp;/g, " ")}</a><br/>
      // </IonItem>
      return <IonItem key={`blog${i}`} lines="full"> 
        <a href={blog.url} rel="noreferrer" target="_blank">{renderHTML(blog.title)}</a><br/>
    </IonItem>
    })
  } else {
    blogsHtml = <IonItem>
      <IonLabel>Fetching names of blogs from WordPress...</IonLabel>
      <IonSpinner/>
    </IonItem>
  }

  return (
    <IonPage>
      <Track/>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>DECIDE project blogs</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonItem>
          <p>
          Our blogs are hosted on this <a href={blogUrl}>WordPress site</a>. 
          You can follow the links below to
          open specific blogs in a separate browser tab.
          </p>
        </IonItem>
        {blogsHtml}
      </IonContent>
    </IonPage>
  );
};

export default Page;
