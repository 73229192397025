import {
  IonPage,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
} from '@ionic/react';

import React, { FunctionComponent, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { 
  chevronForwardOutline,
  chevronForwardSharp,
  chevronDownOutline,
  chevronDownSharp,
  } from 'ionicons/icons';
import './Menu.css';
import Banner from './Banner'
import MenuItemsContext from '../context/MenuItemsContext'
import AppContext from '../context/AppContext'
//import LoginBlock from './LoginBlock'
import Notice from './Notice'

const Menu: FunctionComponent = () => {

  const location = useLocation()
  const { menuItems, visibleSubgroup, setVisibleSubgroup } = useContext(MenuItemsContext)
  const {  setPath, setShowOpts } = useContext(AppContext)

  const urlMenuItem = menuItems.find(({ url }) => url === location.pathname)
  let urlMenuItemparentPath = ''
  if (urlMenuItem){
    const pathElements = urlMenuItem.path.split("-"); pathElements.pop()
    urlMenuItemparentPath = pathElements.join('-')
  }

  //console.log('path', path)
  //console.log('visibleSubgroup', visibleSubgroup)
 
  function toggleSubgroup (path: string | undefined, isGrouping: boolean) {

    //console.log('toggleSubgroup path', path)

    if (isGrouping && path && visibleSubgroup !== path) {
      // Make this the visible sub-group
      setVisibleSubgroup(path)
    } else if (path && visibleSubgroup === path) {
      // Already the visible subgroup, so need to toggle it off
      // by making it's parent the visible subgroup
      const pathElements = path.split("-"); pathElements.pop()
      const parentPath = pathElements.join('-')
      setVisibleSubgroup(parentPath)
    } else if (path && !isGrouping) {
      // Ensure that the parent group of this item is the active group
      const pathElements = path.split("-"); pathElements.pop()
      const parentPath = pathElements.join('-')
      setVisibleSubgroup(parentPath)
    } else {
      setVisibleSubgroup('')
    }
  }

  function getEndIcon (path: string | undefined) {

    //console.log('path', path)
    //console.log('visibleSubgroup', visibleSubgroup)
    //console.log('urlMenuItemparentPath', urlMenuItemparentPath)

    if (path && urlMenuItemparentPath.startsWith(path)) {
      // Contains the item linking to the currently displayed URL, so must
      // stay 'open'
      return <IonIcon slot="end" ios={chevronDownSharp} md={chevronDownOutline} />
    } else if (path && visibleSubgroup === path) {
      // This is the visible subgroup
      return <IonIcon slot="end" ios={chevronDownSharp} md={chevronDownOutline} />
    } else if (path && visibleSubgroup.startsWith(path)) {
      // If the path is in the path of the visible subgroup
      return <IonIcon slot="end" ios={chevronDownSharp} md={chevronDownOutline} />
    } else {
      // The subgroup is not open
      return <IonIcon slot="end" ios={chevronForwardSharp} md={chevronForwardOutline} />
    }
  }

  function itemHidden(path: string): boolean {

    const pathElements = path.split("-"); pathElements.pop()
    const parentPath = pathElements.join('-')
    const pathLength =  path.split("-").length

    if (
      // Is a main menu item
      pathLength === 2 ||
      // Visible if parent of this element is an ancestor of menu item
      urlMenuItemparentPath.startsWith(parentPath) ||
      visibleSubgroup.startsWith(parentPath)
    ) {
      return false
    } else {
      return true
    }
  }

  let els: any[] = []

  const createItems = (path: string, els: any[]) => {

    const pathElements = path.split("-")
    const pathLength = pathElements.length

    //console.log(menuItems)
    
    menuItems.filter(appPage => {

        const elPathElements = appPage.path.split("-")
        const elPathLength = elPathElements.length
        return appPage.path.startsWith(path) && elPathLength === pathLength + 1

      }).forEach((appPage) => {
      
        const routerLink = appPage.url ? appPage.url : ''
        const className:any = location.pathname === appPage.url ? 'selected' : ''
        const onClick = () => {
          //console.log("clicked path", appPage.path)
          const noUrl = appPage.url ? false : true
          toggleSubgroup(appPage.path, noUrl)
          setPath(appPage.url)
          setShowOpts(appPage.opts)
        }
        const hidden = itemHidden(appPage.path)
        const indent = `${appPage.path.split("-").length - 2}em`
        const endIcon:any = appPage.url === '' ? getEndIcon(appPage.path) : null

        let item 
        if (routerLink) {
          item = (
            <IonItem style={{marginLeft: indent}} className={className} routerLink={routerLink} key={appPage.path} onClick={onClick} hidden={hidden} routerDirection="none" detail={false}>
              <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
              <IonLabel>{appPage.title}</IonLabel>
              {endIcon}
            </IonItem>
          )
        } else {
          // Submenu
          item = (
            <IonItem style={{marginLeft: indent}} className={className} key={appPage.path} button={true} onClick={onClick} hidden={hidden} routerDirection="none" detail={false}>
              <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
              <IonLabel>{appPage.title}</IonLabel>
              {endIcon}
            </IonItem>
          )
        }
        if (appPage.url) {
          item = (
            <IonMenuToggle key={els.length} autoHide={false}>
              {item}
            </IonMenuToggle>
          )
        }
        els.push(item)
        if (!appPage.url) {
          // Create submenu items
          createItems(appPage.path, els)
        }
      })
    return(
      <IonList>
        {els}
      </IonList>
    )
  }

  return (
    <IonPage>
      <IonContent>
        <Banner />
        <Notice text={`
          For more information on the development cycle and how you can influence it, see here:
          `} colour="warning" link="/info/in_development" linkText="further info" />
        {createItems('main', els)}
        {/* <LoginBlock /> */}
      </IonContent>
    </IonPage>
  );
};
export default Menu;
