import React, { FunctionComponent, useContext, useEffect} from 'react'
import { Circle, Polygon } from 'react-leaflet'
import ScoreContext from '../context/ScoreContext'
import AppContext from '../context/AppContext'
const circleToPolygon = require('circle-to-polygon')
const polygonClipping = require('polygon-clipping')

interface ScoreTargetCircleProps {
  radius: number,
  map: any
}
const ScoreTargetCircle: FunctionComponent<ScoreTargetCircleProps> = ({map, radius}) => {

  const { showDecideScoreTarget, mapLat, mapLon} = useContext(AppContext)
  const { lat, lon, scoreImageUrl } = useContext(ScoreContext)
 //const { clickLat, clickLon } = useContext(ScoreContext)

  useEffect(() => {
    map.invalidateSize()
  })

  if (showDecideScoreTarget) {
    // Current decide score centre
    if (scoreImageUrl) {
      const gjsonC=circleToPolygon([mapLon, mapLat], radius, 128)
      //const gjsonC=circleToPolygon([clickLon, clickLat], radius, 128)

      const gjsonD=circleToPolygon([lon, lat], radius, 128)
      const clip = polygonClipping.difference([gjsonC.coordinates[0]], [gjsonD.coordinates[0]])
      if (clip.length) {
        const clipll = clip[0][0].map((c:any) => [c[1], c[0]])
        return <Polygon 
          positions={clipll}
          fillOpacity={0}
          pathOptions={{ color: 'black', dashArray: '1,4', weight: 2 }} 
        />
      } else {
        return <Circle 
          center={{lat: mapLat, lng: mapLon}}
          //center={{lat: clickLat, lng: clickLon}}

          fillOpacity={0}
          radius={radius}
          pathOptions={{ color: 'black', dashArray: '1,4', weight: 2}} 
        />
      }
    } else {
      return <Circle 
        center={{lat: mapLat, lng: mapLon}}
        //center={{lat: clickLat, lng: clickLon}}

        fillOpacity={0}
        radius={radius}
        pathOptions={{ color: 'black', dashArray: '1,4', weight: 2}} 
      />
    }
  } else {
    return <></>
  }
}
export default ScoreTargetCircle