import React, { FunctionComponent, useEffect, useContext } from 'react'
import { useIonViewDidEnter } from '@ionic/react'
import { ZoomControl, ScaleControl } from 'react-leaflet'
import MapButton from './MapButton'
//import Version from './Version'
import AppContext from '../context/AppContext'
import { logActivity } from '../js/logapi.js' 

interface InvalidateSizeI {
  map: any
}
const InvalidateSize: FunctionComponent<InvalidateSizeI>  = ({map}) => {
  // Might not need this here if in useEffect
  useIonViewDidEnter(() => {
    //https://ionicframework.com/docs/react/lifecycle
    //console.log('ionViewDidEnter - invalidateSize')
    try {
      map.invalidateSize()
    } catch (e) {
      //console.log()
    }
  })
  return null
}

interface StandardMapControlsI {
  map: any
}
const StandardMapControls: FunctionComponent<StandardMapControlsI> = ({map}) => {

  const { surveyLink } = useContext(AppContext)

  useEffect(() => {
    map.invalidateSize()
    setTimeout(() => {
      // Seems to be needed in app is initialised on map page
      map.invalidateSize()
    }, 300)
  })
  return (<>
      <InvalidateSize map={map}/>
      <ZoomControl  position='topright'/>
      <MapButton map={map} position='bottomright' title='Feedback' id='decide-map-feedback-button' width={110}
        onClick={() => {
          logActivity({id: 'feedback_link', identity: 'map_button'})
          // window.location.href = `${surveyLink}`
          //window.open(surveyLink, '_blank')
          //window.open(surveyLink)
          window.location.href=`${surveyLink}`
        }}>
        Give feedback
      </MapButton>
      <ScaleControl position='bottomright' />
  </>)
}
export default StandardMapControls