import React, { FunctionComponent, useContext } from 'react'
import { IonContent } from '@ionic/react'
import AppContext from '../context/AppContext'
import './HelpPanel.css'

interface HelpPanelProps {}

// Because many different types of controls are served by this
// help system and there are different ways of identifying them all,
// code to invoke the help system has to be looked after in several
// different places. Note that they help system should be invoked on
// both click and mouseover events. The former is required to service
// the mobile environment where there is no mouseover event.
//
// ScoreMapOpts.tsx invokes help on the DECIDE Score map side
// menu control. It should take care of any new controls added
// so long as they are in a list item with unique id.
//
// MapButton.tsx invokes help on any 'EastyButtons' added to the map.
// It should take care of any new buttons added this way. Each must
// have a unique id.
//
// ScoreMap.tsx invokes help on any core leaflet controls added to the
// map. This must be explicitly updated (UseEffect hook) if these
// map controls change, using the class names leaflet uses for the
// controls.
//
// Other custom controls for the map, e.g. DecideScoreLegend.js and
// GrControl.js are responsible for invoking the help system themselves.
//
// MenuTabs.tsx invokes the help for the tab buttons on the main side menu.
// The buttons all have the identifiers of the format, 'tab-button-tab<n>'.
// Put the help in the mainMenu object.
//
// ScoreMapPage.tsx invokes the help for the tab buttons on the main map page.
// The buttons all have the identifiers of the format, 'tab-button-tab<n>'.
// Put the help in the mapTabs object.
//
// When a new control is added - whatever the type - the help for the
// control must be defined in this module.

const HelpPanel: FunctionComponent<HelpPanelProps> = () => {

  const { helpIdentifier } = useContext(AppContext)
  const { configTaxa } = useContext(AppContext)

  return <IonContent scrollY={true} id='help-ion-content'>
    <div 
      id='help-panel-content' 
      dangerouslySetInnerHTML={{__html:getHelpText(helpIdentifier, configTaxa)}}
    >
    </div>
  </IonContent>
}
export default HelpPanel

const getHelpText = (id:string, configTaxa:Array<any>):string => {

  const mainMenu:any = {
    'show-help': `The <b>show help</b> toggle can be used to show or hide
    help as desired. When shown, text appears below the map when you hover over,
    or click on, a control. For a more detailed explanation on how to use the 
    DECIDE map and the background to the DECIDE project, visit the Home Page.`,

    'opacity-slider': `The <b>Opacity</b> slider changes the transparency of the 
    DECIDE map. Move it to the left to make the layer more 
    transparent in order to see more of the basemap through it.`,

    'threshold-slider': `The <b>filter</b> slider changes the percentage of the 
    DECIDE map layer that is visible. By default 100% of the layer is 
    visible but if you change it to 60%, for example, then the 60% 
    with the highest priority for new records is displayed. The 40% 
    which is filtered out corresponds to the lower recording priorities.`,

    'fogmap-toggle': `The <b>Fog map</b> toggle allows you to change 
    the representation of the DECIDE map to a  
    different style. It fogs out areas of low recording 
    priority on the map and makes the basemap most visible in 
    areas where records are most valuable.`,

    'max-suggestions': `Use the <b>Maximum suggestions</b> control
    to change the <b>maximum number of 
    suggestion pins</b> generated when the <b>Get suggestions</b>
    button is clicked. Note that this number is a <i>maximum</i> -
    there may be fewer if public 
    access is thought to be limited in the area.`,

    'access-row': `This control toggles the display of 
    <b>Rights of Way</b> on and off. Retrieval of this information
    can be slow, so we recommended setting it to off as default.`,

    'access-points': `This control toggles the display of 
    <b>Access points</b> on and off. Retrieval of this information
    can be slow, so we recommended setting it to off as default.`,

    'access-greenspace': `This control toggles the display of 
    <b>Greenspace</b> on and off. Retrieval of this information
    can be slow, so we recommended setting it to off as default.`,

    'access-open': `This control toggles the display of 
    <b>Open Access Land</b> on and off. Retrieval of this information
    can be slow, so we recommended setting it to off as default. Note
    that this information is only available for parts of the country.`,

    'access-nt': `This control toggles the display of 
    <b>National Trust Land</b> on and off. Retrieval of this information
    can be slow, so we recommended setting it to off as default.`,

    'access-london': `This control toggles the display of 
    <b>Rights of Way in London</b> on and off. Retrieval of this information
    can be slow, so we recommended setting it to off as default.`,

    'season-select': `Use this to select the month you are interested in. 
    The information shown on the Tool will be based on the 
    species likely to be found that month. Set to <i>All months</i> if you 
    don’t want to plan for a specific period.`,

    'tab-button-tab1': `This tab button takes you to the main app menu.`,

    'tab-button-tab2': `This tab button takes you to the main map options.`,
  }

  configTaxa.forEach(t => {
    mainMenu[`taxa-${t.machine}`] = `Select this option to display the DECIDE map for
    <b>${t.human.toLowerCase()}</b> when you click on the <b>Create DECIDE map</b> button.`
  })

  //console.log('mainMenu', mainMenu)

  const mapButton = {
    'decide-score-button': `Click the <b>Create DECIDE map</b> button 
    to see the relative priorities for recording within 
    a 5 km radius circle (the 'DECIDE map layer'). In the default map, 
    brighter (more yellow) areas are the highest priority.`,

    'decide-nudge-button': `Click the <b>Get suggestions</b> button for suggestions 
    of places to visit. These are displayed on the map as information pins 
    surrounded by a small 200m radius circle. They show places that are a 
    relatively high priority for new records and have public access close 
    by. You can click on any pin to see more details about that place.
    You can also click anywhere within the decide map, regardless of 
    recording priority or access, for more information about the recording 
    prospects there.`,

    'decide-map-feedback-button': `Clicking this button will open a
    web page in another tab on your browser where you can give us
    feedback. You can submit feedback as often as you like. 
    Comments and suggestions help us to improve the look and 
    functionality of the Tool.`,

    'current-location-button': `If you click this tool button, your
    browser will try to centre the map on your current location. You
    may be prompted to allow the browser to use your location.`,

    'find-place-button': `If you click this toolbutton, you will be
    able to enter a grid reference or a place name to relocate the 
    map. If a place name search returns more than one result, they are
    presented in a list and you can select one of them.`
  }

  const customControl = {
    'react-grid-ref-control': `This control displays the grid reference at any
    point you click on the map. If you click on the control, you can change the
    type of grid reference displayed (e.g. Six figure GR or Tetrad GR). With the control
    expanded you can see all GR resolutions at once. Click the cross icon to minimise
    again. You can also display the GR square itself on the map where you click by 
    checking the <b>Show square</b> checkbox.`,

    'react-score-legend': `This legend indicates how the colours of the DECIDE 
    map layer change depending on the relative recording priority at any given 
    point. Details about how the different priority levels are calculated 
    can be found in the <i>Find out More</i> menu on the Home page.`
  }

  const leafletControl = {
    'layers-toggle': `Use this control to change the basemap layers, e.g. to
    change to a satellite view.`,

    'layers-list': `Use this control to change the basemap layers, e.g. to
    change to a satellite view.`,

    'zoom-in': `You can use this control to zoom in on the map (but you
    can also use your mouse wheel).`,

    'zoom-out': `You can use this control to zoom out of the map (but you
    can also use your mouse wheel).`,

    'scale': `This is a simple scalebar for the map.`
  }

  const mapTabs = {
    'tab-button-tab0': `Use this tab button to display the main app menu bar.`,

    'tab-button-tab1': `This tab displays the main map. 
    You can toggle between the map page and the help page by using these tabs.`,

    'tab-button-tab2': `This tab takes you to the home page where there is 
    more detailed information about the DECIDE project.`,

    'tab-button-tab3': `This tab displays the full help for the using the main map.
    You can toggle between the map and the help page by using these tabs.`
  }

  const unknown = {
    'unknown': `No help object for ${id}`
  }

  let helpObj:any = {}, helpObjKey='', key=''

  if (id.startsWith('main-menu#')) {
    helpObjKey='main-menu'
    helpObj = mainMenu
    key = id.substring(10)
  } else if (id.startsWith('map-button#')) {
    helpObjKey='map-button'
    helpObj = mapButton
    key = id.substring(11)
  } else if (id.startsWith('custom-control#')) {
    helpObjKey='custom-control'
    helpObj = customControl
    key = id.substring(15)
  } else if (id.startsWith('leaflet-control#')) {
    helpObjKey='leaflet-control'
    helpObj = leafletControl
    key = id.substring(16)
  } else if (id.startsWith('map-tabs#')) {
    helpObjKey='map-tabs'
    helpObj = mapTabs
    key = id.substring(9)
  } else {
    helpObj = unknown
    key = 'unknown'
  }

  const html = helpObj[key] ? helpObj[key] : `No key for ${key} in ${helpObjKey}`

  return html
}