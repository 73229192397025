import {
  IonRouterOutlet, IonMenu, IonIcon,
  IonTabs, IonTabBar, IonTabButton,
  IonLabel
} from '@ionic/react';
import { 
  menuOutline,
  menuSharp,
  settingsOutline,
  settingsSharp,
  informationCircleOutline, 
  informationCircleSharp,  
  informationOutline, 
  informationSharp,  
  mapOutline, 
  mapSharp,
  newspaperOutline,
  newspaperSharp,
  logoWordpress
} from 'ionicons/icons';

import { Route, Switch } from 'react-router-dom'
import React, { useState, useContext, useEffect } from "react";
import Menu from './Menu';
import Options from '../pages/Options';
import MenuItemsContext, { AppPage } from '../context/MenuItemsContext'
import AppContext from '../context/AppContext'

const MenuTabs: React.FC = () => {

  const [menuItems, setMenuItems]=useState<AppPage[]>([])
  const [visibleSubgroup, setVisibleSubgroup] = useState('')
  const { tool, setHelpIdentifier } = useContext(AppContext)

  const appPages: AppPage[] = [
    {
      path: 'main-map',
      title: 'DECIDE Map',
      url: '/opts/scoremap/map',
      iosIcon: mapOutline,
      mdIcon: mapSharp,
      opts: true
    },
    { path: 'main-info',
      title: 'Information',
      url: '',
      iosIcon: informationCircleOutline,
      mdIcon: informationCircleSharp
    },
    { path: 'main-blogs',
      title: 'Blogs',
      url: '/app/blogs',
      iosIcon: logoWordpress,
      mdIcon: logoWordpress
    },
    { path: 'main-newsletter',
      title: 'MyDECIDE',
      url: '/app/newsletter',
      iosIcon: newspaperOutline,
      mdIcon: newspaperSharp
    },
  ]

  if (menuItems.length === 0) {

    let menu:string
    if (window.location.hostname.indexOf('staging') > -1 || window.location.hostname === 'localhost') {
      // On staging cluster and localhost, menu comes from infopages_edit.
      menu = `${process.env.REACT_APP_DBAPI_URL}infopages_edit/menu.txt`
    } else {
      menu = `${process.env.REACT_APP_DBAPI_URL}infopages/menu.txt`
    }

    fetch(menu,
      {headers : { 
          'Content-Type': 'application/text',
          'Accept': 'application/text'
        }
      }).then(function(response){
        return response.text()
      }).then(function(text) {
        const txt = text.trim()
        let iMenu = 0
        let iItem = 0
        let path = ''
        const currentMenus = ['main-info']
        txt.split('\n').forEach(line => {
          const leadingSpace = line.search(/\S|$/)  // length of leading space
          const splitLine = line.split('##')
          if (splitLine.length === 1) {
            // This is a submenu
            path = `${currentMenus[leadingSpace]}-menu${++iMenu}`
            currentMenus[leadingSpace+1] = path
          } else {
            // This is a menu item pointing to a resource
            path = `${currentMenus[leadingSpace]}-info${++iItem}`
          }
          const item = {
            path: path,
            title: splitLine[0].trim(),
            url: splitLine[1] ? `/info/${splitLine[1].trim()}` : '',
            iosIcon: informationOutline,
            mdIcon: informationSharp,
          }
          appPages.push(item)
        })
        setMenuItems(appPages)
      })
  }

  const mi = {
    menuItems: menuItems,
    setMenuItems: setMenuItems,
    visibleSubgroup: visibleSubgroup,
    setVisibleSubgroup: setVisibleSubgroup
  }

  useEffect(() => {
    // Hook the tab buttons up to the help system
    const menuTabs=document.getElementById('menu-tabs') as HTMLElement
    const opts = menuTabs.getElementsByTagName('ion-tab-button')
    for (let i = 0; i < opts.length; i++) {
      opts[i].onclick = e => {
        setHelpIdentifier(`main-menu#${opts[i].id}`)
      }
      opts[i].onmouseover = e => {
        setHelpIdentifier(`main-menu#${opts[i].id}`)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //const pathname = useLocation().pathname
  //const showTabBar = tool !== '' && pathname.includes(tool)
  const showTabBar = false // Tool options tab bar taken out for ver 1.0

  return (
    <MenuItemsContext.Provider value={mi}>
      <IonMenu contentId="main" type="overlay">
        <IonTabs>
          <IonRouterOutlet>
            { /* Note can also use regular expression in path spec  */}
            <Switch>
              <Route path="/opts/:tool" component={Options} />
              <Route path={["/menu", "/info", "/app", "/page"]} component={Menu} />
              <Route path="(.*?)" component={Menu} />
            </Switch>
          </IonRouterOutlet>
          <IonTabBar id='menu-tabs' slot="bottom" style={{display: showTabBar ? '' : 'none'}} >
            <IonTabButton tab="tab1" href={`/app/${tool}`}>
              <IonIcon ios={menuOutline} md={menuSharp} />
              <IonLabel>Menu</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab2" href={`/opts/${tool}`} >
              <IonIcon ios={settingsOutline} md={settingsSharp} />
              <IonLabel>Options</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonMenu>
    </MenuItemsContext.Provider>
  );
};
export default MenuTabs;
