import { IonContent, IonPage } from '@ionic/react'
import React from 'react'
import Banner from '../components/Banner'
import ScoreMapOpts from '../components/ScoreMapOpts'

import { useParams } from 'react-router'
import './Page.css'

const Options: React.FC = () => {

  const { tool } = useParams<{ tool: string; }>()
  //console.log('tool', tool)

  let toolopts
  switch(tool)  {
    case 'scoremap':
      toolopts = <ScoreMapOpts/>
      break
    default:
      toolopts = ''
  }
  return (
    <IonPage>
      <IonContent>
        <Banner />
        {toolopts}
      </IonContent>
    </IonPage>
  )
}
export default Options
