import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from './ReactGAProxy.js'

const Track = () => {
  const location = useLocation()
  useEffect(() => {
    console.log('GA pageview', location.pathname)
    //ReactGA.pageview(location.pathname) // Record a pageview for the given page // UA deprecated
    ReactGA.send({ hitType: "pageview", page: location.pathname }) //GA4
  }, [location])

  return null
}

export default Track
