import React, { useContext } from 'react'
import { IonSpinner } from '@ionic/react'
import ScoreContext from '../context/ScoreContext'
import AppContext from '../context/AppContext'
import './SpinnerCentre.css'

const SpinnerCentre: React.FC = () => {
 
  const { scoreImageUrl, prowGeojson, greenspaceGeojson, accesspointGeojson, accesslandGeojson, ntGeojson, londonGeojson, nudgesJson} = useContext(ScoreContext)
  const { busy } = useContext(AppContext)

  const show = (scoreImageUrl === 'fetch' 
    || prowGeojson === 'fetch' 
    || greenspaceGeojson === 'fetch' 
    || accesspointGeojson === 'fetch' 
    || accesslandGeojson  === 'fetch'
    || ntGeojson  === 'fetch'
    || londonGeojson  === 'fetch'
    || nudgesJson === 'fetch'
    || busy
  ) 
  return (
    <div className='spinnerDivOuter' style={{ display: show ? '' : 'none' }}>
      <div className='spinnerDivInner'><IonSpinner /></div>
    </div>
  )
}
export default SpinnerCentre