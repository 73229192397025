import React, { useEffect, useContext, useRef } from 'react'
import { GeoJSON } from 'react-leaflet'
import ScoreContext from '../context/ScoreContext'
import AppContext from '../context/AppContext'
import NudgePopover from './NudgePopover'
//import ReactGA from './ReactGAProxy.js'
import { logActivity } from '../js/logapi.js' 
import L from 'leaflet'
import { getGr } from './bigr'
import 'leaflet/dist/leaflet.css'
import './NudgeLayer.css'
import { setNudgeSvgStyles } from '../js/nudge-svg-styles'
// Leaflet icon workaround here: https://github.com/PaulLeCam/react-leaflet/issues/453
import iconInfoBlue from '../images/info-icon-blue.png'
import iconInfoRed from '../images/info-icon-red.png'
import iconInfoGrey from '../images/info-icon-grey.png'
import iconShadow from '../images/marker-shadow.png'

const infoPinBlue = L.icon({
  iconUrl: iconInfoBlue,
  shadowUrl: iconShadow,
  iconSize: [18, 27],
  shadowSize: [27, 27],
  iconAnchor:   [9, 27],
  shadowAnchor:   [9, 27],
})

const infoPinRed = L.icon({
  iconUrl: iconInfoRed,
  shadowUrl: iconShadow,
  iconSize: [18, 27],
  shadowSize: [27, 27],
  iconAnchor:   [9, 27],
  shadowAnchor:   [9, 27],
})

const infoPinGrey = L.icon({
  iconUrl: iconInfoGrey,
  shadowUrl: iconShadow,
  iconSize: [18, 27],
  shadowSize: [27, 27],
  iconAnchor:   [9, 27],
  shadowAnchor:   [9, 27],
})



const NudgeLayer = () => {
  const { lat, lon, singleNudgesJson, setSingleNudgesJson, nudgesJson, setNudgesJson, nudgeJson, setNudgeJson, clickLat, clickLon, setClickLat, setClickLon } = useContext(ScoreContext)
  const { selectedFtr, setSelectedFtr, setSelectedFtrMetadata, month } = useContext(ScoreContext)
  const { maxNudges, radiusDecideScore, scoreTaxa, setBusy } = useContext(AppContext)

  useEffect(() => {
    // Set up the nudge svg styles
    setNudgeSvgStyles()
  }, [])

  const fetchingNudges = useRef(false)
  const fetchingNudge = useRef(false)

  if (nudgesJson==='fetch' && !fetchingNudges.current){

    fetchingNudges.current = true

    // ReactGA.event({
    //   category: 'Decide score map',
    //   action: `Fetch Nudges for ${scoreTaxa[0]}`
    // })
    logActivity({id: 'score_nudges', lat: lat, lon: lon, rad: radiusDecideScore, name: scoreTaxa[0], min: maxNudges < 5 ? maxNudges : 5, max: maxNudges, month: month}) 

    fetch(`${process.env.REACT_APP_SCORE_SERVER_URL}score/nudges?lat=${lat}&lon=${lon}&rad=${radiusDecideScore}&name=${scoreTaxa[0]}&min=${maxNudges < 5 ? maxNudges : 5}&max=${maxNudges}&month=${month}`, {method: 'GET'})
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(String(response.status))
        } else {
          return response.json()
        }
      })
      .then((json) => {
        if (json.length === 1 && !json[0]) {
          setNudgesJson('')
        } else {
          // Enrich the features with metdata values
          const geojson = JSON.parse(JSON.parse(json[0]).geojson)
          const metadata = JSON.parse(json[0]).metadata
          geojson.features.forEach((ftr) => {
            ftr.properties.this_is_water = metadata.this_is_water
            ftr.properties.meta_from_accessible = metadata.meta_from_accessible
            // Store the 100 m square (6 fig GR associated with this nudge)
            const grs = getGr(ftr.geometry.coordinates[1], ftr.geometry.coordinates[0])
            ftr.properties.gr6fig = grs['p100']
          })
          const result = {geojson: JSON.stringify(geojson), metadata: metadata}
          const sresult = [JSON.stringify(result)]
          setNudgesJson(sresult)
        }
      }).catch((error) => {
        console.log('Error trapped', error)
        setNudgesJson('')
      }).finally(() => {
        fetchingNudges.current=false
      })
  }

  // Only get nudgeJson if GR of clicked lat
  // does not match the GR of an existing nudge.
  // const singleNudges = singleNudgesJson ? JSON.parse(JSON.parse(singleNudgesJson).geojson).features : []
  // const nudges = nudgesJson && nudgesJson !== 'fetch' ? JSON.parse(JSON.parse(nudgesJson).geojson).features : []
  // const allNudges = [...singleNudges, ...nudges]
  // const grs = getGr(clickLat, clickLon)
  // const existingNudge = allNudges.find(f => {
  //   //console.log(grs['p100'], f.properties.gr6fig)
  //   return f.properties.gr6fig === grs['p100']
  // })

  if (nudgeJson==='fetch' && !fetchingNudge.current){

    fetchingNudge.current = true

    // ReactGA.event({
    //   category: 'Decide score map',
    //   action: `Fetch single nudge pin for ${scoreTaxa[0]}`
    // })
    logActivity({id: 'score_nudge', lat: clickLat, lon: clickLon, dlat: lat, dlon: lon, drad: radiusDecideScore, name: scoreTaxa[0], month: month}) 

    const url=`${process.env.REACT_APP_SCORE_SERVER_URL}score/nudge?
          lat=${clickLat}&
          lon=${clickLon}&
          name=${scoreTaxa[0]}&
          dlat=${lat}&
          dlon=${lon}&
          drad=${radiusDecideScore}&
          month=${month}
        `.replace(/\s/g, '')

    fetch(url, {method: 'GET'})
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(String(response.status))
        } else {
          return response.json()
        }
      })
      .then((json) => {
        if (json.length === 1 && !json[0]) {
          setNudgeJson('')
        } else {
          setNudgeJson(json[0])
          // Update the single nudge collection
          const nudgeMeta = JSON.parse(json[0]).metadata
          let singleNudges, metadata
          if (singleNudgesJson === '') {
            singleNudges = {type: 'FeatureCollection', features: []}
            metadata = JSON.parse(json[0]).metadata
          } else {
            singleNudges = JSON.parse(JSON.parse(singleNudgesJson).geojson)
            metadata = nudgeMeta
          }
          // Update single nudges collection by adding in this nudge to existing
          const nudge = JSON.parse(JSON.parse(json[0]).geojson)
          let ftr = nudge.features[0]

          // Store the 100 m square (6 fig GR associated with this nudge)
          const grs = getGr(ftr.geometry.coordinates[1], ftr.geometry.coordinates[0])
          ftr.properties.gr6fig = grs['p100']

          // Check to see if this nudge already exists either in nudges or 
          // single nudges
          const sn = singleNudgesJson ? JSON.parse(JSON.parse(singleNudgesJson).geojson).features : []
          const ns = nudgesJson && nudgesJson !== 'fetch' ? JSON.parse(JSON.parse(nudgesJson).geojson).features : []
          const an = [...sn, ...ns]
          const existingFtr = an.find(f => {
            return f.properties.gr6fig === ftr.properties.gr6fig 
          })

          if (existingFtr) {
            ftr = existingFtr
          } else {
            // Set display flag
            ftr.properties.single = true
            // Transfer metadata to feature
            ftr.properties.this_is_water = nudgeMeta.this_is_water
            ftr.properties.meta_from_accessible = nudgeMeta.meta_from_accessible

            const combinedNudges = {type: 'FeatureCollection', features: [...singleNudges.features, ftr]}
            const result = {geojson: JSON.stringify(combinedNudges), metadata: metadata}
            const sresult = [JSON.stringify(result)]
            setSingleNudgesJson(sresult)
          }
          // Dispaly popup
          setBusy(true)
          setSelectedFtr(ftr)
          //setSelectedHtml('fetch')
          setSelectedFtrMetadata({fetch: 'fetch'})
        }
      }).catch((error) => {
        console.log('Error trapped', error)
        setNudgeJson('')
      }).finally(() => {
        fetchingNudge.current=false
      })
  }

  // Set numbers to empty collection or the combined nudges
  let dataNudges={type: "FeatureCollection", features: []}
  if (nudgesJson !== 'fetch' && nudgesJson !== '') {
    dataNudges=JSON.parse(JSON.parse(nudgesJson).geojson)
  }
  if (singleNudgesJson !== '') {
    const singleNudges=JSON.parse(JSON.parse(singleNudgesJson).geojson)
    dataNudges.features = [...dataNudges.features, ...singleNudges.features]
  }
  
  return(<>
    <NudgePopover/>
    <GeoJSON 
      data={dataNudges}
      key={Math.random()} // Key is always changed (random number) to ensure correct ordering of geojson layers
      pointToLayer={function (feature, latlng) {
        let selected = false
        if (selectedFtr.hasOwnProperty('geometry') && selectedFtr.geometry.coordinates[1] === latlng.lat &&  selectedFtr.geometry.coordinates[0] === latlng.lng) {
          selected = true
        }
        const userGen = feature.properties.hasOwnProperty('single')
        let icon = infoPinBlue
        let radgrad = 'nudge-radgrad2' 
        if (selected) {
          icon=infoPinRed
          radgrad='nudge-radgrad3'
        } else if (userGen) {
          icon=infoPinGrey
          radgrad='nudge-radgrad4'
        }
        const c = L.circle(latlng, 200,
          {
            className: radgrad,
            stroke: false,
            pane: 'nudge_circles'
          }
        )
        const s = L.marker(latlng, {icon: icon})
        return L.layerGroup([c, s])
      }}
      onEachFeature={function(feature, layer) {
        const layers = []
        if (layer._layers) {
          Object.keys(layer._layers).forEach((key) => {
            layers.push(layer._layers[key])
          })
        } else {
          layers.push(layer)
        }

        layers.forEach(l => {
          l.on('click' , async function(e) {
            // setBusy(true)
            // setSelectedFtr(feature)
            // setSelectedHtml('fetch')
          
            // Previous way of doing this using setSelectedFtr and setSelectedHtml
            // caused problems due to race conditions etc, because when the click
            // in a circle propogated to map, setNudgeJson was kicked off via
            // that route. So now it is just left to setNudgeJson on map click and
            // that is updated to check if nudge already exists. But since
            // clicking on icon does not propagate to map, we need to set
            // click lat/lon and setNudgeJson here if symbol clicked.
            //console.log(e)
            if (e.sourceTarget._icon) {
              setClickLat(e.latlng.lat)
              setClickLon(e.latlng.lng)
              setNudgeJson('fetch')
            }
          })
        })
      }}
    />
  </>)

}
export default NudgeLayer