import React, { useContext } from 'react'
import { TileLayer, LayersControl, Pane  } from 'react-leaflet'
import ScoreContext from '../context/ScoreContext'

const StandardLayers = () => {
  const { useFogmap } = useContext(ScoreContext)

  // Key attribute with random generator used to ensure that a layer is always
  // turned off when fog map toggle used.
  return (<>
    <Pane name="nudge_circles" style={{ zIndex: 599 }}>
    </Pane>
    <LayersControl position="topright">
      <LayersControl.BaseLayer checked={!useFogmap} name="Standard OpenStreetMap">
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors. Use of data is subject to <a style="cursor: pointer" onclick="termsAndConditions()">terms and conditions</a>.'
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="ESRI Satellite">
        <TileLayer
          url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
          attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        />
      </LayersControl.BaseLayer>
      {/* <LayersControl.BaseLayer name="Google Satellite">
        <TileLayer
          url='http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          attribution='Google'
          maxZoom={20}
          subdomains={['mt0','mt1','mt2','mt3']}
        />
      </LayersControl.BaseLayer> */}
      <LayersControl.BaseLayer checked={useFogmap} name="CartoDB Voyager (no labels)">
        <TileLayer
          url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png'
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          subdomains='abcd'
          maxZoom={19}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer  key={Math.random()} checked={false} name="Simple map">
        <TileLayer
          url='https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png'
          attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
      </LayersControl.BaseLayer>

      <LayersControl.Overlay name="Toner roads &amp; labels" checked={useFogmap}>
        <Pane name="toner_roads" style={{ zIndex: 598 }}>
          <TileLayer
            url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-hybrid/{z}/{x}/{y}{r}.png"
            attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            subdomains='abcd'
            minZoom={0}
            maxZoom={20}
          />
        </Pane>
      </LayersControl.Overlay>

    </LayersControl>
  </>)
}
export default StandardLayers