import React, { FunctionComponent, useContext } from "react"
//import banner from '../images/decide-logo-500.gif'
import banner from '../images/Decide_artwork_RGB.png'
import Version from './Version'
import AppContext from '../context/AppContext'
import { logActivity } from '../js/logapi.js' 

const Banner: FunctionComponent = () => {

  const { surveyLink } = useContext(AppContext)

  return (<div style={{padding: '0 0.5em 0.5em 0.5em'}}>
    <img src={banner} id='menuBanner' alt="Decide banner" />
    <div>Version: <Version /> 
      <button 
        style={{
          float: 'right', 
          backgroundColor: '#ffc900',
          marginTop: '0.1em',
          padding: '0.2em 0.3em',
          borderRadius: '4px',
          color: 'black'
        }} 
        //onClick={() => window.location.href=`${surveyLink}`}>
        onClick={() => {
          logActivity({id: 'feedback_link', identity: 'banner_button'})
          //window.open(surveyLink, '_blank')
          //window.open(surveyLink)
          window.location.href=`${surveyLink}`
        }}>
        Give feedback
      </button>
    </div>
  </div>)
}

export default Banner