import ReactGA from 'react-ga4'
const Cookies = require('js-cookie')

const gaEnabled = process.env.REACT_APP_GA_DISABLED === 'false'

const ReactGAProxy = {
  initialize: function (...args) {
    if (gaEnabled) {
      ReactGA.initialize(...args)
    }
  },
  event: function (...args) {
    if (gaEnabled) {
      ReactGA.event(...args)
    }
  },
  set: function (...args) {
    if (gaEnabled) {
      ReactGA.set(...args)
    }
  },
  pageview: function (...args) {
    if (gaEnabled) {
      //ReactGA.pageview(...args)
      ReactGA.send(...args)
    }
  },
  send: function (...args) {
    if (gaEnabled) {
      ReactGA.send(...args)
    }
  },
  clientId: function() {
    if (gaEnabled) {
      // UA deprecated
      // if (ReactGA.ga().getAll) {
      //   return ReactGA.ga().getAll()[0].get('clientId')
      // } else {
      //   return 'ga_uninitialised'
      // }
      const clientid = Cookies.get('_ga')
      if (clientid) {
        return clientid.substring(6)
      } else {
        return 'ga_uninitialised'
      }
    } else {
      return 'ga_unavailable'
    }
  }
}

export default ReactGAProxy
