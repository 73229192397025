import React, { FunctionComponent, useContext, useEffect } from 'react'
import {ImageOverlay, Circle } from 'react-leaflet'
import L from 'leaflet'
import ScoreContext from '../context/ScoreContext'
import AppContext from '../context/AppContext'
//import ReactGA from './ReactGAProxy.js'
import { logActivity } from '../js/logapi.js' 
import './ScoreOverlay.css'

interface ScoreOverlayProps {
  map: any,
}

let fetching = false

const ScoreOverlay: FunctionComponent<ScoreOverlayProps> = ({ map }) => {
  const { lat, lon, scoreImageUrl, setScoreImageUrl, radius, setRadius, useFogmap, month } = useContext(ScoreContext)
  const { showDecideScoreLayer, opacityDecideScoreLayer, scaleDecideScoreLayer, thresholdDecideScoreLayer } = useContext(AppContext)
  const { radiusDecideScore } = useContext(AppContext)
  const { scoreTaxa } = useContext(AppContext)

  useEffect(() => {
    if (scoreImageUrl==='fetch' && !fetching) {

      // ReactGA.event({
      //   category: 'Decide score map',
      //   action: `Fetch Decide score map for ${scoreTaxa[0]}`
      // })

      if (lat) {
        fetching = true
        // Build URL
        let urlScore
        if (useFogmap) {
          // For version 1 URLs below will change depending on selected
          // month which is available from the month state variable
          // in the score context

          logActivity({
            id: 'score_point_rad_pre', 
            lat: lat, 
            lon: lon,
            name: scoreTaxa[0],
            rad: radiusDecideScore, 
            local: scaleDecideScoreLayer, 
            colour: 'fog',
            bins: '0,0.2,0.4,0.6,0.8,1',
            month: month
          }) 

          urlScore = `
            ${process.env.REACT_APP_SCORE_SERVER_URL}score/point-rad-pre?
            lat=${lat}&lon=${lon}&rad=${radiusDecideScore}
            &name=${scoreTaxa[0]}
            &local=${scaleDecideScoreLayer}
            &threshold=0
            &colour=fog
            &bins=0,0.2,0.4,0.6,0.8,1
            &month=${month}
          `.replace(/\s/g, '')
        } else {

          logActivity({
            id: 'score_point_rad_pre', 
            lat: lat, 
            lon: lon, 
            name: scoreTaxa[0],
            rad: radiusDecideScore, 
            local: scaleDecideScoreLayer, 
            threshold: thresholdDecideScoreLayer,
            colour: 'viridis_truncated',
            month: month
          }) 

          urlScore = `
            ${process.env.REACT_APP_SCORE_SERVER_URL}score/point-rad-pre?
            lat=${lat}&lon=${lon}&rad=${radiusDecideScore}
            &name=${scoreTaxa[0]}
            &local=${scaleDecideScoreLayer}
            &threshold=${thresholdDecideScoreLayer}
            &colour=viridis_truncated
            &month=${month}
          `.replace(/\s/g, '')
        }

        fetch(urlScore)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(String(response.status))
          } else {
            return response.blob()
          }
        }).then((image) => {
          if (image) {
            setScoreImageUrl(URL.createObjectURL(image))
          }
        }).catch((error) => {
          console.log('error', error)
          setScoreImageUrl('')
        }).finally(() => {
          fetching = false
          setRadius(radiusDecideScore)
        })
      } else {
        setScoreImageUrl('')
      }
    }
  })

  if (scoreImageUrl && scoreImageUrl !== 'fetch') {

    const circle = L.circle([lat, lon], radius).addTo(map)
    //const circle = L.circle([clickLat, clickLon], radius).addTo(map)

    const bounds = circle.getBounds()
    map.removeLayer(circle)
    //console.log(scoreImageUrl)
    if (showDecideScoreLayer && scoreImageUrl !== 'fetch') {
      // Note setting className - either on Circle element or in path options - does set the class anywhere in resulting dom
      // so weight set to 2.1 instead of 2 so it can be used to target the path in CSS.
      return (<>
        <Circle center={[lat, lon]} radius={radius} pathOptions={{ color: 'black', fillOpacity:0, weight: 2.1}} />
        {/* <Circle center={[clickLat, clickLon]} radius={radius} pathOptions={{ color: 'black', fillOpacity:0, weight: 2.1}} /> */}

        <ImageOverlay url={scoreImageUrl} bounds={bounds} opacity={opacityDecideScoreLayer} />
      </>)
    } else {
      return null
    }
  } else {
    return null
  }
}
export default ScoreOverlay
