import React, { FunctionComponent } from "react"
import { Link } from 'react-router-dom'
import {IonCard, IonCardContent} from "@ionic/react"

const Notice: FunctionComponent<{
  text:string,
  colour?: string,
  link?: string,
  linkText?: string
}> = ({children, text, link, linkText, colour}) => {

  let linkItem: any = ''
  if (link && linkText) {
    linkItem = <Link to={link}>{linkText}</Link>
  }
  return (
    <IonCard color={colour}>
      <IonCardContent>{text}{linkItem}</IonCardContent>
    </IonCard>
  )
}

export default Notice