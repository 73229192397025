import React, { useContext, useEffect } from 'react'
import {
  IonIcon, IonTabBar, IonTabButton,
  IonLabel,
  IonMenuButton
} from '@ionic/react'

import {   
  mapOutline, 
  mapSharp,
  helpOutline, 
  helpSharp,
  homeOutline,
  homeSharp
} from 'ionicons/icons'

import AppContext from '../context/AppContext'

const PageTabs: React.FC = () => {

  const { setHelpIdentifier} = useContext(AppContext)
  
  useEffect(() => {
    // Hook the tab buttons up to the help system
    const menuTabs=document.getElementById('map-tabs') as HTMLElement
    const opts = menuTabs.getElementsByTagName('ion-tab-button')
    for (let i = 0; i < opts.length; i++) {
      opts[i].onclick = e => {
        setHelpIdentifier(`map-tabs#${opts[i].id}`)
      }
      opts[i].onmouseover = e => {
        setHelpIdentifier(`map-tabs#${opts[i].id}`)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Using a tab bar without IonRouterOutlet or IonTabs
  return (<>
    <IonTabBar slot="top" id='map-tabs' >
      <IonTabButton className='ion-hide-lg-up' tab="tab0">
        <IonMenuButton/>
      </IonTabButton>
      <IonTabButton tab="tab2" href='/info/decide_info'>
        <IonIcon ios={homeOutline} md={homeSharp} />
        <IonLabel>Home</IonLabel>
      </IonTabButton>
      <IonTabButton tab="tab1" href='/opts/scoremap/map'>
        <IonIcon ios={mapOutline} md={mapSharp} />
        <IonLabel>Map</IonLabel>
      </IonTabButton>
      <IonTabButton tab="tab3" href='/info/decide_quick_start'>
        <IonIcon ios={helpOutline} md={helpSharp} />
        <IonLabel>Help</IonLabel>
      </IonTabButton>
    </IonTabBar>
  </>)
}
export default PageTabs
