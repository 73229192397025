import { useEffect, useContext } from 'react'
import AppContext from '../context/AppContext'
import './DecideScoreLegend.css'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import * as interpolate from 'color-interpolate'

// This is not typescript because I couldn't find out how to extend Leaflet
// control using typescript.

const DecideScoreLegend = ({map, position, render} ) => {

  // The render argument is simply there to force this control
  // to re-render whenever one of the buttons above it may
  // have changed otherwise their position is swapped.

  const { scoreColour } = useContext(AppContext)
  const { opacityDecideScoreLayer } = useContext(AppContext)
  const { setHelpIdentifier} = useContext(AppContext)

  useEffect(() => {

    //console.log('rendering legend')
    //console.log('scoreColour', scoreColour)

    const scoreAsColour = (i) => {
      let colormap
      switch (scoreColour) {
        case 'yellow_red':
          colormap = interpolate(['#ffffcc','#ffeda0','#fed976','#feb24c','#fd8d3c','#fc4e2a','#e31a1c','#bd0026','#800026'])
          break
        case 'viridis_truncated':
          colormap = interpolate(['#440154', '#414487', '#2A788E', '#22A884', '#7AD151', '#FDE725'])
          // In the score server, the viridis_truncated style is calculated like this:
          // cols <- tail(viridis(140), 100)
          // Here we need adjust the score i value to correspond to this truncated scale.
          i = 40/140 + i * 100/140
          //i = 0.3 + i * 0.7
          break
        case 'cividis':
          colormap = interpolate(['white', 'black']) // Would need creating if we ever use.
          break
        default:
          colormap = interpolate(['white', 'black'])
          break
      }
      return colormap(i)
    }

    const fogmapColour = (i) => {
      // New scheme for v0.3.2
      // 0-0.2	#1A1A1A	40%
      // 0.2-0.4	#7F7F7F	40%
      // 0.4-0.6	#CCCCCC	40%
      // 0.6-0.8	#FFFFFF	40%
      // 0.8-1	#FFFF00	15%
      if (i <= 0.2) {
        return '#1A1A1A'
      } else if (i <= 0.4) {
        return '#7F7F7F'
      }else if (i <= 0.6) {
        return '#CCCCCC'
      }else if (i <= 0.8) {
        return '#FFFFFF'
      } else {
        return '#FFFF00'
      }
    }

    const fogmapOpacity = (i) => {
      // New scheme for v0.3.2
      // 0-0.2	#1A1A1A	40%
      // 0.2-0.4	#7F7F7F	40%
      // 0.4-0.6	#CCCCCC	40%
      // 0.6-0.8	#FFFFFF	40%
      // 0.8-1	#FFFF00	15%
      if (i <= 0.8) {
        return 0.4
      } else {
        return 0.15
      }
    }

    const legendSwatches = (n) => {
      let html = ''
      for (let i=0; i<n; i++) {
        html = `${html} 
          <div class='swatch' 
            style='
              background-color: ${scoreColour  === 'fog' ? fogmapColour(i/(n-1)) : scoreAsColour(i/(n-1))}; 
              opacity: ${scoreColour  === 'fog' ? fogmapOpacity(i/(n-1)) : opacityDecideScoreLayer};
              border-radius: ${i===0 ? '0 0 0 4px' : i===n-1 ? '0 0 4px 0' : '0' }
          '></div>`
      }
      return html
    }

    L.Control.DecideLegend = L.Control.extend({
      onAdd: function() {
          var div = L.DomUtil.create('div')
          div.id ='react-score-legend'
          //------------------>
          const legend = `<div>
            <div id='react-score-legend-top'>
              <div id='react-score-legend-top-left'>   
                <img src='/assets/arrow.png' alt='Decide score arrow' width='80px' height='15px'/>
              </div>  
              <div id='react-score-legend-top-right'>Higher priority for recording</div>
            </div>
            <div id='react-score-legend-bottom'>
              ${legendSwatches(170)}
            </div>
          </div>`

          div.innerHTML  = legend
          return div
      },
      onRemove: function() {
        //console.log('Remove legend')
        // Nothing to do here
      }
    });
    
    L.control.decidelegend = function(opts) {
        return new L.Control.DecideLegend(opts);
    }
    
    const cc = L.control.decidelegend({ position: position }).addTo(map);

    // Event hanlders
    const ctl = document.getElementById('react-score-legend')
    ctl.onclick = e => {
      setHelpIdentifier(`custom-control#react-score-legend`)
    }
    ctl.onmouseover = e => {
      setHelpIdentifier(`custom-control#react-score-legend`)
    }

    return () => {
      map.removeControl(cc);
      //var div = L.DomUtil.get('react-score-legend')
      //div.innerHTML = 'remove'
      //console.log(div)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreColour, opacityDecideScoreLayer, render])
  return null
}
export default DecideScoreLegend
