import React from 'react';

export interface AppPage {
  path: string,
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  opts?: boolean;
}

interface MenuItems {
  menuItems: AppPage[];
  setMenuItems: any;
  visibleSubgroup: string;
  setVisibleSubgroup: any
}

const MenuItemsContext = React.createContext<MenuItems>({
  menuItems: [],
  setMenuItems: null,
  visibleSubgroup: '',
  setVisibleSubgroup: null
});

export default MenuItemsContext;