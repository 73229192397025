import React from 'react';

interface User {
  username: string;
  setUsername: any;
  email: string;
  setEmail: any;
  firstname: string;
  setFirstname: any;
  fullname: string;
  setFullname: any;
  mediaFeed: string;
  setMediaFeed: any;
  useData: boolean;
  setUseData: any;
  interestMoths: boolean;
  setInterestMoths: any;
  interestButterflies: boolean;
  setInterestButterflies: any;
  interestGrasshoppers: boolean;
  setInterestGrasshoppers: any;
  generalConsent: boolean;
  setGeneralConsent: any;
  commsConsent: string;
  setCommsConsent: any;
  otherPlatformConsent: boolean;
  setOtherPlatformConsent: any;
  ispotUsername: string;
  setIspotUsername: any;
  irecordUsername: string;
  setIrecordUsername: any;
}

const UserContext = React.createContext<User>({
  username: '',
  setUsername: null,
  email: '',
  setEmail: null,
  firstname: '',
  setFirstname: null,
  fullname: '',
  setFullname: null,
  mediaFeed: '',
  setMediaFeed: null,
  useData: false,
  setUseData: null,
  interestMoths: false,
  setInterestMoths: null,
  interestButterflies: false,
  setInterestButterflies: null,
  interestGrasshoppers: false,
  setInterestGrasshoppers: null,
  generalConsent: false,
  setGeneralConsent: null,
  commsConsent: '',
  setCommsConsent: null,
  otherPlatformConsent: false,
  setOtherPlatformConsent: null,
  ispotUsername: '',
  setIspotUsername: null,
  irecordUsername: '',
  setIrecordUsername: null,
});

export default UserContext;