import React, { useRef, useContext, FunctionComponent, useState } from 'react'
import { IonPopover, IonToolbar, IonModal, IonHeader, IonFooter, IonContent, IonGrid, IonRow, IonCol, IonIcon, IonCard, IonCardContent } from '@ionic/react'
import { 
  closeCircleOutline,
  closeCircleSharp,
  helpCircleOutline,
  helpCircleSharp,
  linkOutline,
  linkSharp
} from 'ionicons/icons';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  // PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share'

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  // PinterestIcon,
  TwitterIcon,
  WhatsappIcon
} from 'react-share'

import ScoreContext from '../context/ScoreContext'
import AppContext from '../context/AppContext'
//import ReactMarkdown from 'react-markdown/with-html'
//import ReactGA from './ReactGAProxy.js'
import { logActivity } from '../js/logapi.js' 
//import { getGr } from './bigr'
import './NudgePopover.css'

interface NudgeHelp {
  nudgeHelp: string;
  setNudgeHelp: any;
}
const HelpContext = React.createContext<NudgeHelp>({
  nudgeHelp: '',
  setNudgeHelp: null
})

const NudgePopover: React.FC = (props) => {

  const { lat, lon, selectedFtr, selectedFtrMetadata, setSelectedFtrMetadata, month } = useContext(ScoreContext)
  const { radiusDecideScore, scoreTaxa, setBusy } = useContext(AppContext)
  const [nudgeHelp, setNudgeHelp] = useState('')
  const help = {
    nudgeHelp: nudgeHelp,
    setNudgeHelp: setNudgeHelp
  }
  const fetching: any = useRef(false)

  if (selectedFtrMetadata.fetch && !fetching.current){

    fetching.current = true

    // ReactGA.event({
    //   category: 'Decide score map',
    //   action: `Fetch Nudge text for ${scoreTaxa[0]}`
    // })
    logActivity({id: 'score_nudge_meta', lat: selectedFtr.geometry.coordinates[1], lon: selectedFtr.geometry.coordinates[0], rad: 200, dlat: lat, dlon: lon, drad: radiusDecideScore, name: scoreTaxa[0], month: month}) 

    //setBusy(true)
    const urlMetadata=`
      ${process.env.REACT_APP_SCORE_SERVER_URL}score/nudge-meta?
      lat=${selectedFtr.geometry.coordinates[1]}&
      lon=${selectedFtr.geometry.coordinates[0]}&
      rad=${200}&
      name=${scoreTaxa[0]}&
      dlat=${lat}&
      dlon=${lon}&
      drad=${radiusDecideScore}&
      month=${month}
    `.replace(/\s/g, '')

    fetch(urlMetadata, {method: 'GET'})
    .then(response => {
      if (response.status !== 200) {
        throw new Error(String(response.status))
      } else {
        return response.json()
      }
    })
    .then(json => {
      //console.log(selectedFtr)
      //console.log(JSON.parse(json))
      setSelectedFtrMetadata(JSON.parse(json))
    })
    .catch((error) => {
      console.log('error', error)
      setSelectedFtrMetadata({error: true})
    })
    .finally(() => {
      fetching.current=false
      setBusy(false)
    })
    return null
  } else if (selectedFtrMetadata.error) {

    return <PopoverMessage text='Cannot get suggestion information right now.'/>

  } else if (selectedFtrMetadata.richness && selectedFtr.properties['this_is_water']){

    return <PopoverMessage text='This is a water body. You are unlikely to record any species at this location. Try the margins instead.'/>

  } else if (selectedFtr.properties && selectedFtrMetadata.richness) {

    return <>
      <HelpContext.Provider value={help}>
        <IonModal id='nudgePopup' cssClass='nudgePopover' isOpen={true} 
          onDidDismiss={() => {
            setSelectedFtrMetadata({})
            setNudgeHelp('')
          }}>
          <NudgeHeader />
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <NudgePopupSection>Recording priority analysis</NudgePopupSection>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol><ModelUncertainty/></IonCol>
                <IonCol><Params/></IonCol>
              </IonRow>
              <IonRow>
                <IonCol><SpeciesRecording/></IonCol>
                <IonCol><ModelPrediction/></IonCol>
              </IonRow>
              {/* <IonRow>
                
              </IonRow> */}
              <IonRow>
                <IonCol>
                  <NudgePopupSection>About this location</NudgePopupSection>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol><Accessibility/></IonCol>
                <IonCol><Habitat/></IonCol>
              </IonRow>
              <IonRow>
                <IonCol><AccessibilityFeatures/></IonCol>
                <IonCol><Links/></IonCol>
              </IonRow>
              {/* <IonRow>
                
              </IonRow>
              <IonRow>
                
              </IonRow> */}
            </IonGrid>
          </IonContent>
          <IonFooter>
            <InfoArea/>
            <NudgePopupShare/>
          </IonFooter>
        </IonModal>
      </HelpContext.Provider>
    </>
  } else {
    return <></>
  }
}
export default NudgePopover

type MessageProps = {
  text: string
}

const PopoverMessage: FunctionComponent<MessageProps> = ({text}) => {

  const { setSelectedFtrMetadata } = useContext(ScoreContext)

  return <IonPopover id='nudgePopup' cssClass='nudgePopover' isOpen={true} 
    onDidDismiss={() => {
      setSelectedFtrMetadata({})
    }}>
      <IonToolbar>
        <div style={{margin: '0.5em 0.5em'}}>{text}</div>
        <div slot='end' style={{cursor: 'pointer', margin: '0 0.5em'}}>
          <IonIcon size='large' ios={closeCircleSharp} md={closeCircleOutline} 
            onClick={() => {
              setSelectedFtrMetadata({})
            }}/>
        </div>
      </IonToolbar>
  </IonPopover>
}

const NudgeHeader: React.FC = () => {

  const { setSelectedFtrMetadata, selectedFtr } = useContext(ScoreContext)
  const { setNudgeHelp } = useContext(HelpContext)

  //console.log('selectedFtr', selectedFtr)
  //console.log('selectedFtrMetadata', selectedFtrMetadata)

  // Summarise the position of the decide score
  const qscore = Math.round(selectedFtr.properties.decide_quantile_local * 100)
  let perc = Math.floor(qscore/10) * 10
  let percText
  if (qscore >= 50) {
    perc = 100 - perc
    if (perc === 0) perc = 10
    percText = 'Top'
  } else {
    perc = perc + 10
    percText = 'Lowest'
  }
  let head
  if (qscore >= 80) {
    head = 'Very high'
  } else if (qscore >= 60) {
    head = 'High'
  } else if (qscore >= 40) {
    head = 'Medium'
  } else if (qscore >= 20) {
      head = 'Low'
  } else {
    head = 'Very low'
  }
  const subhead = `${percText} ${perc}% for recording priority in the local area`
  //const uncertaintyText1Class = `nudge-text-main nudge-text-colour1`
  
  return <> 
    <IonHeader>
      <IonToolbar>
        <div style={{margin: '0 0.5em'}} className='highlight'>
          <div style={{fontSize: '1.4em'}}>Recording priority: <span style={{color: 'blue'}}>{head}</span></div>
            
          <div style={{fontSize: '0.9em'}}>{subhead}</div>
        </div>
        <div slot='end' style={{cursor: 'pointer', margin: '0 0.5em'}}>
          <IonIcon size='large' ios={closeCircleSharp} md={closeCircleOutline} 
            onClick={() => {
              setSelectedFtrMetadata({})
              setNudgeHelp('')
            }}
          />
        </div>
      </IonToolbar>
    </IonHeader>
  </>
}

const Params: React.FC = () => {

  const { scoreTaxa } = useContext(AppContext)
  const { month } = useContext(ScoreContext)
  const { configTaxa } = useContext(AppContext)

  const months: { [char: string]: string } = {
    'all_year': 'all months',
    'jan': 'January',
    'feb': 'February',
    'mar': 'March',
    'apr': 'April',
    'may': 'May',
    'jun': 'June',
    'jul': 'July',
    'aug': 'August',
    'sep': 'September',
    'oct': 'October',
    'nov': 'November',
    'dec': 'December'
  }

  const taxa: { [char: string]: string } = {}
  configTaxa.forEach(t => {
    return taxa[t.machine]=t.human
  })

  const paramText1 = 'Scope'
  const paramText2 = `${taxa[scoreTaxa[0]]} in ${months[month]}`
  return <> 
    <IonCard className='postit'>
      <IonCardContent>
        <div className='nudge-text-stnd nudge-text-bold nudge-text-colour0'>{paramText1}</div>
        <div className='nudge-text-stnd'>{paramText2}</div>
      </IonCardContent>
    </IonCard>
  </>
}

const ModelUncertainty: React.FC = () => {

  const { selectedFtrMetadata } = useContext(ScoreContext)

  let uncertaintyText1 
  const uncertaintyText1Class = `nudge-text-main nudge-text-colour1`
  let uncertaintyText2 

  // Summarise the position of the decide score
  const uscore = Math.round(selectedFtrMetadata.uncertainty * 100)
  let perc = Math.floor(uscore/10) * 10
  let percText
  if (uscore >= 50) {
    perc = 100 - perc
    if (perc === 0) perc = 10
    percText = 'Top'
  } else {
    perc = perc + 10
    percText = 'Lowest'
  }
  if (uscore >= 80) {
    uncertaintyText1 = 'Very high'
  } else if (uscore >= 60) {
    uncertaintyText1 = 'High'
  } else if (uscore >= 40) {
    uncertaintyText1 = 'Medium'
  } else if (uscore >= 20) {
    uncertaintyText1 = 'Low'
  } else {
    uncertaintyText1 = 'Very low'
  }
  uncertaintyText2 = `${percText} ${perc}% in the local area`

  return <> 
    <IonCard className='postit'>
      <IonCardContent>
        <div className='nudge-text-stnd nudge-text-bold nudge-text-colour0'>Model uncertainty</div>
        <div className={uncertaintyText1Class}>{uncertaintyText1}</div>
        <div className='nudge-text-stnd'>{uncertaintyText2}</div>
      </IonCardContent>
    </IonCard>
  </>
}

const ModelPrediction: React.FC = () => {

  const { selectedFtrMetadata } = useContext(ScoreContext)
  const uncertaintyText1Class = `nudge-text-main nudge-text-colour1`
  const predictionText1 = `${Math.round(selectedFtrMetadata.richness)} species`

  return <> 
    <IonCard className='postit'>
      <IonCardContent>
        <HelpIcon help={<p>
          <b>Our model predicts</b> is the total number of species 
          our model calculates could be present. Only a fraction 
          of these will be seen on any single visit, so the number 
          may seem higher than expected. Your records are 
          helpful to improve these modelled predictions.
        </p>}/>
        <div className='nudge-text-stnd nudge-text-bold' style={{marginLeft: '18px'}}>Our model predicts</div>
        <div className={uncertaintyText1Class}>{predictionText1}</div>
        <div className='nudge-text-stnd'>at this location</div>
      </IonCardContent>
    </IonCard>
  </>
}

const SpeciesRecording: React.FC = () => {

  const { selectedFtrMetadata } = useContext(ScoreContext)

  let spRecordingText1 = "Known recording activity"
  let spRecordingText2
  const uncertaintyText1Class = `nudge-text-main nudge-text-colour1`

  const daysSince2000 = selectedFtrMetadata.days_since_2000
  let lastRecDate
  if (daysSince2000) {
    //const md = 86400000 //number of milliseconds in a day
    // @ts-ignore
    const recDate = new Date('January 01, 2000 00:00:00')
    recDate.setDate(recDate.getDate() + daysSince2000)
    //console.log('recDate', recDate)
    const dd = String(recDate.getDate()).padStart(2, '0');
    const mm = String(recDate.getMonth() + 1).padStart(2, '0'); //January is 0
    const yyyy = recDate.getFullYear();
    lastRecDate = `${dd}/${mm}/${yyyy}`
  } else {
    lastRecDate = 'none since 2000'
  }
  console.log('Last record date is:', lastRecDate)

  const effort = selectedFtrMetadata.effort
  if (effort < 0.25) {
    spRecordingText2 = 'Low'
  } else if (effort < 0.7) {
    spRecordingText2 = 'Medium'
  } else {
    spRecordingText2 = 'High'
  }

  return <> 
    <IonCard className='postit'>
      <IonCardContent>
        <HelpIcon help={<p>
            <b>Known recording activity</b> is based on recency of records across the 
            year submitted to Butterfly Conservation, including records in the current 
            year submitted to iRecord and iSpot (<a href='/info/faq_maps_models' target='_blank' rel='noreferrer'>More info</a>).
          </p>}
        />
        <div className='nudge-text-stnd nudge-text-bold'>{spRecordingText1}</div>
        {/* <div className={spRecordingText1Class}>{spRecordingText1}</div> */}
        <div className={uncertaintyText1Class} >{spRecordingText2}</div>
        <div className='nudge-text-stnd'>at this location</div>
      </IonCardContent>
    </IonCard>
  </>
}

const Accessibility: React.FC = () => {

  const { selectedFtr } = useContext(ScoreContext)

  const uAccess:Array<string> = getAccessFeatures(selectedFtr.properties['feats'])

  const accessibilityText1 = uAccess.length ? 'Accessible' : 'No known access'
  //const accessibilityText1Class = uAccess.length ? `nudge-text-main nudge-text-colour3` : `nudge-text-main nudge-text-colour1`
  const accessibilityText1Class = `nudge-text-main nudge-text-colour1`

  return <> 
    <IonCard className='postit'>
      <IonCardContent>
        <HelpIcon help={<p>
            <b>Accessibility</b> information can be shown on the map by switching on
            the access layers using the controls on the left. Useful layers  
            include <i>Rights of Way</i>, <i>Greenspace</i> and <i>Open Access</i>. Please remember that keeping 
            safe and legal when recording is <a href='/info/user_agreement' target='_blank' rel='noreferrer'>your responsibility</a>.
          </p>}/>
        <div className='nudge-text-stnd nudge-text-bold' style={{marginLeft: '18px'}}>Accessibility</div>
        <div className={accessibilityText1Class}>{accessibilityText1}</div>
      </IonCardContent>
    </IonCard>
  </>
}

const AccessibilityFeatures: React.FC = () => {

  const { selectedFtr } = useContext(ScoreContext)

  const uAccess:Array<string> = getAccessFeatures(selectedFtr.properties['feats'])

  let access
  if (uAccess.length === 0) {
    access = 'No access features'
  } else if (uAccess.length > 1) {
    access = `${uAccess.slice(0,-1).join(', ')} and ${uAccess.slice(-1)}`
  } else {
    access = `${uAccess[0]}`
  }

  const accessibilityFtrsText1 = access
  const accessibilityText1Class = `nudge-text-main nudge-text-colour1`
 
  return <> 
    <IonCard className='postit'>
      <IonCardContent>
        <div className='nudge-text-stnd nudge-text-bold'>Nearby access features</div>
        <div className={accessibilityText1Class}>{accessibilityFtrsText1}</div>
      </IonCardContent>
    </IonCard>
  </>
}

const Links: React.FC = () => {

  const { selectedFtr } = useContext(ScoreContext)

  const lat = selectedFtr.geometry.coordinates[1]
  const lon = selectedFtr.geometry.coordinates[0]

  // Google directions. Supports origin parameter, but if left blank, tries to use user's current location
  const urlGoogle = `https://www.google.co.uk/maps/dir/?api=1&destination=${lat},${lon}`
  // OS mappinf via Bing MapBox
  const urlOS = `https://www.bing.com/maps?cp=${lat}~${lon}&lvl=14&style=s`
  // Grag a grid ref
  //const urlGrabGridRef = `https://www.bnhs.co.uk/2019/technology/grabagridref/gagr.php?gr=${selectedFtr.properties.gr6fig}`

  return <> 
    <IonCard className='postit'>
      <IonCardContent>
        <div className='nudge-text-stnd nudge-text-bold'>Useful links</div>
        <div>
          <a href={urlGoogle} target='_blank' rel='noreferrer' 
            onClick={e => {
              logActivity({id: 'nudge_link_clicked', type: 'google_directions', url: urlGoogle})
            }}
          >Directions from Google</a>
        </div>
        <div>
          <a href={urlOS} target='_blank' rel='noreferrer'
            onClick={e => {
              logActivity({id: 'nudge_link_clicked', type: 'osmap', url: urlOS})
            }}
          >Location on OS map</a></div>
        {/* <div><a href={urlGrabGridRef} target='_blank' rel='noreferrer'>Grab a grid ref</a></div> */}
      </IonCardContent>
    </IonCard>
  </>
}

const Habitat: React.FC = () => {

  const { selectedFtrMetadata } = useContext(ScoreContext)

  const lc = ["broadleaved woodland","coniferous woodland","arable land","grassland","grassland","grassland",
    "grassland","fen, marsh & swamp","heather","heather grassland","bog","inland rock",
    "saltwater","freshwater","coastal","coastal","coastal","coastal",
    "saltmarsh","urban","suburban"]

  let habitat:string
  if (Array.isArray(selectedFtrMetadata.landcover)) {

    let landcover: Array<number>
    if (!Array.isArray(selectedFtrMetadata.landcover)) {
      landcover = [selectedFtrMetadata.landcover]
    } else {
      landcover = selectedFtrMetadata.landcover
    }
    // First filter removes values of zero which somtimes come through
    // Second filter removes duplicate values
    const lctext = landcover.filter(i => i>0).map(i => lc[i-1]).filter((x, i, a) => a.indexOf(x) === i)
    if (lctext.length > 1) {
      habitat = `${lctext.slice(0,-1).join(', ')} and ${lctext.slice(-1)}`
    } else if (lctext.length === 1){
      habitat = `${lctext[0]}`
    } else {
      habitat = 'none'
    }
  } else {
    habitat = `${lc[selectedFtrMetadata.landcover-1]}`
  }

  const HabitatText1 = habitat
  const habitatText1Class = `nudge-text-main nudge-text-colour1`
  const habitatText2 = `can be found within 200m of this location`

  return <> 
    <IonCard className='postit'> 
      <IonCardContent>
        <HelpIcon help={<p>
          <b>Habitat</b> is based on the dominant habitats in the UKCEH Landcover Map 
          from satellite data. This is indicative and provided to help you; 
          habitats on the ground may differ from these predictions.
        </p>}/>
        <div className='nudge-text-stnd nudge-text-bold nudge-text-colour0'  style={{marginLeft: '18px'}}>Habitat</div>
        <div className={habitatText1Class}>{HabitatText1}</div>
        <div className='nudge-text-stnd'>{habitatText2}</div>
      </IonCardContent>
    </IonCard>
  </>
}

interface HelpIconProps {
  help: any,
}
const HelpIcon: FunctionComponent<HelpIconProps> = ({help}) => {
  const { setNudgeHelp } = useContext(HelpContext)
  return <>
    <IonIcon  
      ios={helpCircleSharp} 
      md={helpCircleOutline} 
      style={{
        fontSize: '18px',
        float: 'right', 
        cursor: 'pointer', 
        marginTop: '0.1em',
      }}
      onClick={() => setNudgeHelp(help)}
    />
  </>
}

const NudgePopupSection: React.FC = (props) => {

  return <> 
     <IonCard className='postit' style={{backgroundColor: '#eeeeee'}}>
      <div className='nudge-text-section-header'>{props.children}</div>
    </IonCard>
  </>
}

const NudgePopupShare: React.FC = (props) => {

  const { month, lat, lon, selectedFtr } = useContext(ScoreContext)
  const { scoreTaxa } = useContext(AppContext)

  const nudgeLat = Math.round(selectedFtr.geometry.coordinates[1] * 100000)/100000
  const nudgeLon = Math.round(selectedFtr.geometry.coordinates[0] * 100000)/100000
  const dLat = Math.round(lat * 100000)/100000
  const dLon = Math.round(lon * 100000)/100000
  const shareUrl = `${window.location.origin}/opts/scoremap/map?group=${scoreTaxa[0]}&month=${month}&lat=${nudgeLat}&lon=${nudgeLon}&dlat=${dLat}&dlon=${dLon}&zoom=12&score=true&suggestions=false&popup=true`
  
  //console.log(shareUrl)
  const title = 'DECIDE recording suggestion'

  return <> 
     <IonCard className='postit' style={{backgroundColor: 'white', paddingTop: '0.5em'}}>
      <IonCardContent>

        <TwitterShareButton
          url={shareUrl}
          title={title}
          onClick={e=>logActivity({id: 'nudge_share', platform: 'twitter', url: shareUrl})}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <FacebookShareButton
          url={shareUrl}
          quote={title}
          onClick={e=>logActivity({id: 'nudge_share', platform: 'fb', url: shareUrl})}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <LinkedinShareButton url={shareUrl}
          onClick={e=>logActivity({id: 'nudge_share', platform: 'linkedin', url: shareUrl})}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>

        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          onClick={e=>logActivity({id: 'nudge_share', platform: 'whatsapp', url: shareUrl})}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
        >
          <div onClick={e=>{
              // If the onClick goes in EmailShareButton, for some reason it stops
              // the email link being invoked, but it works if it goes here.
              logActivity({id: 'nudge_share', platform: 'email', url: shareUrl})
            }
          }>
            <EmailIcon size={32} round />
          </div>
        </EmailShareButton>

        {/* <PinterestShareButton
          url={shareUrl}
          media={decideLogo}
        >
          <PinterestIcon size={32} round />
        </PinterestShareButton> */}

        <IonIcon  
          ios={linkSharp} 
          md={linkOutline} 
          onClick={() => {
            navigator.clipboard.writeText(shareUrl);
            logActivity({id: 'nudge_link_copied', url: shareUrl})
          }}
          style={{
            fontSize: '32px',
            marginLeft: '20px',
            cursor: 'pointer', 
          }}
        />

      </IonCardContent>
    </IonCard>
  </>

}

const InfoArea: React.FC = (props) => {
  const { nudgeHelp, setNudgeHelp } = useContext(HelpContext)
  const disp = nudgeHelp !== '' ? 'inline' : 'none'
  return <div style={{display: disp}}> 
    <IonCard style={{backgroundColor: 'white', height: 'auto'}}>
      <IonCardContent style={{textAlign: 'left'}}>
        <IonIcon  
          ios={closeCircleSharp} 
          md={closeCircleOutline} 
          style={{
            fontSize: '18px',
            float: 'right', 
            cursor: 'pointer', 
          }}
          onClick={() => setNudgeHelp('')}
        />
        {nudgeHelp}
      </IonCardContent>
    </IonCard>
  </div>
}

function getAccessFeatures(afeats:string) {
  let uAccess: Array<string>
    if (afeats) {
      uAccess = Array.from(new Set(afeats.split(','))).map(a => `${a.toLowerCase()}`) //.map(a => `'${a}'`)
      // Replace boat abbreviation
      // console.log(uAccess)
      uAccess = uAccess.map(a => {
        if (a.toLowerCase() === "boat") {
          return "Public byway"
        } else {
          return a
        }
      })
    } else {
      uAccess = []
    }
  return uAccess
}